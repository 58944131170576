<template>
  <div class="collection card" >
    <a
        v-if="pads.length === 0"
        class="collection-item"
        @contextmenu="onContextMenu($event)"
    >
      <span>Please create pad</span>
    </a>
    <div v-if="pads.length > 0">
      <router-link
          v-for="pad in pads" :key="pad.id"
          :to="pad.edit? `` : `/pad/${pad.id}`"
          :ref="`pad_${pad.id}`"
          class="collection-item"
          @contextmenu="onContextMenu($event)"
      >
        <span v-if="!pad.edit">{{pad.name}}</span>
        <label v-if="pad.edit">
          <input
              :value="pad.name"
              :ref="`pad_input_${pad.id}`"
              @keydown.enter="updatePad($event)"
              @focusout="updatePad($event)"
          />
        </label>
      </router-link>
    </div>
  </div>

  <context-menu
      v-model:show="show"
      :options="optionsComponent"
  >
    <context-menu-item>
      <div class="menu_item" @click="createPad">
        <i class="material-icons">add</i> <span>New Pad</span>
      </div>
    </context-menu-item>

    <context-menu-item>
      <div class="menu_item" @click="UpPad">
        <i class="material-icons">arrow_upward</i> <span>Up Pad</span>
      </div>
    </context-menu-item>

    <context-menu-item>
      <div class="menu_item" @click="DownPad">
        <i class="material-icons">arrow_downward</i> <span>Down Pad</span>
      </div>
    </context-menu-item>



    <context-menu-group label="To folder">
      <context-menu-item v-for="elem in folders" :key="elem.id">
        <div class="menu_item" @click="updatePadFolder(elem.id)">
          <i class="material-icons">folder</i> <span>{{elem.name}}</span>
        </div>
      </context-menu-item>

      <context-menu-item>
        <div class="menu_item" @click="updatePadFolder('none')">
          <i class="material-icons">folder</i> <span>None</span>
        </div>
      </context-menu-item>

    </context-menu-group>

    <context-menu-item>
      <div class="menu_item" @click="renamePad">
        <i class="material-icons">mode_edit</i> <span>Rename Pad</span>
      </div>
    </context-menu-item>

    <context-menu-item>
      <div class="menu_item" @click="deletePad">
        <i class="material-icons">delete</i> <span>Delete Pad</span>
      </div>
    </context-menu-item>

  </context-menu>
</template>

<script>
import axios from "axios";

export default {
  name: "PadListCollectionComponent",
  emits: ["update-folder"],
  props: {
    pad: {
      required: false,
    },
    folderId: {
      type: String,
      required: false,
    },
    spaceId: {
      type: String,
      required: true,
    },
    folders:{
      type: Array,
      required: false
    }
  },
  data(){
    return{
      activeId: undefined,
      pads: [],
      show: false,
      optionsComponent: {
        iconFontClass: 'iconfont',
        customClass: "class-a",
        zIndex: 4003,
        minWidth: 230,
        x: 500,
        y: 200
      },
    }
  },
  async mounted() {
      this.pads = (await axios.get(`/pad/${this.spaceId}${this.folderId ? `?folderId=${this.folderId}` : ''}`)).data
  },
  methods:{
    async UpPad(){
      const index =  this.pads.findIndex(value => value.id === this.activeId);
      const prevIndex = index - 1;
      if(index === 0) return;

      const temp = (await axios.patch(`/pad/sort/${this.activeId}`, {
        sortBeforeId: this.pads[prevIndex].id
      }))
      if(temp.status === 200){
        const tempData = this.pads[index]
        this.pads[index] = this.pads[prevIndex]
        this.pads[prevIndex] = tempData
      }

    },
    async DownPad(){
      const index =  this.pads.findIndex(value => value.id === this.activeId);
      const prevIndex = index + 1;
      if(index === this.pads.length-1) return;

      const temp = (await axios.patch(`/pad/sort/${this.activeId}`, {
        sortAfterId: this.pads[prevIndex].id
      }))
      if(temp.status === 200){
        const tempData = this.pads[index]
        this.pads[index] = this.pads[prevIndex]
        this.pads[prevIndex] = tempData
      }

    },
    async onContextMenu(e) {

      e.preventDefault();
      //Set the mouse position
      if(this.activeId !== ''){
        // this.pads.find(value => value.id === this.activeId).edit = false;

        this.activeId = e.target.href.split('/').at(-1);
      }else {
        this.activeId = e.target.href.split('/').at(-1);
      }
      this.optionsComponent.x = e.x;
      this.optionsComponent.y = e.y;
      //Show menu
      this.show = true;
    },
    async createPad() {
      const temp = (await axios.post(`/pad/${this.spaceId}`, {
        name: "new Pad",
        folder_id: this.folderId ?? undefined
      })).data
      this.pads.push(temp)
    },
    async deletePad(){
      const index = this.pads.findIndex(value => value.id === this.activeId);
      await axios.delete(`/pad/${this.activeId}`, );
      this.pads.splice(index, 1);
      this.activeId = '';
    },
    async renamePad(){
      this.pads.find(value => value.id === this.activeId).edit = true;

    },
    async updatePad(e){
      const index = this.pads.findIndex(value => value.id === this.activeId);
      await axios.patch(`/pad/${this.activeId}`, {
        name: e.target.value
      });
      this.pads[index].name = e.target.value;
      this.pads[index].edit = false;
      this.activeId = '';
    },
    async updatePadFolder(folderId){
      const temp = await axios.patch(`/pad/${this.activeId}`, {folder_id: folderId});
      if(temp.status === 200){
        const index = this.pads.findIndex(value => value.id === this.activeId);
        this.$emit('update-folder', folderId)
        this.pads.splice(index, 1);

      }
    }
  }
}
</script>

<style scoped>

</style>