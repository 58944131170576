<template>
  <iframe :src="urlReport" v-if="urlReport"></iframe>
  <h2 v-else>Not found</h2>
</template>

<script>
import axios from "axios";

export default {
  name: "ReportCoverageComponent",
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  data(){
    return {
      urlReport: undefined
    }
  },
  mounted() {
    try{


      axios.get(`${process.env.VUE_APP_REPORT_URL}/coverage/${this.spaceId}/coverage/lcov-report/index.html`)
          .then((res)=> {
            if(res.status === 200){
              this.urlReport = `${process.env.VUE_APP_REPORT_URL}/coverage/${this.spaceId}/coverage/lcov-report/index.html`;
            }
          })
    }catch (e) {
      this.urlReport = undefined
    }

  }
}
</script>

<style scoped>
iframe{
  width: 100%;
  height: 100vh;
}
</style>