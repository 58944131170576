<template>
  <div class="sub" v-for='elem in items' :key='elem.id' :id="elem.id">
    <div class="vtpad-sub_item" >
      <div class="vtpad-sub_item row">
        <div class="vtpad-main_item__checkbox">
          <label> <input type="checkbox" /> <span/> </label>

        </div>
        <div class="vtpad-sub_item__textarea">
          <label>
                      <textarea
                          type='text'
                          class="materialize-textarea"
                          :ref="'textarea_sub_'+elem.id"
                          :id="elem.id"
                          v-model="elem.text"
                          @focusout="saveSubItem($event)"
                          @keydown.ctrl.enter="addSubItem($event)"
                          @keydown.ctrl.delete="deleteSubItem($event)"
                          @keydown.alt.enter="addItem($event)"

                          @keydown.down="nextSubFocused($event)"
                          @keydown.up="prevSubFocused($event)"


                          @keydown.ctrl.left="upSubItem($event)"
                          @keydown.ctrl.right="downSubItem($event)"
                      />
          </label>
        </div>
        <div class="testcases_menu" @click="showTestcase(elem.id)">
          <i class="material-icons">album</i>
        </div>
      </div>
      <testcases-vtpad-component
          v-if="elem.showTestcases"
          :ref="`testcase_list_component__${elem.id}`"
          :item-id="elem.id"
          v-on:open-case="openTestcase"
          v-on:open-case-select-list="openTestcasesSelect"
          :update-case="item.updateCase"/>
    </div>

    <sub-item-component
        :item="elem.subItem"
        :pad-id="padId"
        :mainItemId="item.id"
        v-if="elem.subItem"
        v-on:open-case="openTestcase"
        v-on:open-case-select-list="openTestcasesSelect"/>
  </div>
</template>

<script>
import axios from "axios";
import TestcasesVtpadComponent from "@/components/pads/vtpad/TestcasesVtpadComponent";

export default {
  name: "subItemComponent",
  components: {TestcasesVtpadComponent},
  emits: ["open-case", "open-case-select-list"],

  props: {
    mainItemId: {
      type: String,
    },
    item: {
      type: Object,
      required: true,
    },
    updateCase: Number,
    padId: {
      type: String,
      required: true,
    }
  },
  data(){
    return{
      items: []
    }
  },
  mounted() {
    this.items = this.item
  },
  methods: {
    openTestcase(elemId){
      this.$emit('open-case', elemId)
    },
    openTestcasesSelect(itemId){
      this.$emit('open-case-select-list', itemId, this.mainItemId)
    },
    showTestcase(itemId){
      this.items.find(value => value.id === itemId).showTestcases = !this.items.find(value => value.id === itemId).showTestcases
    },
    async saveSubItem(e){
      const index = this.item.findIndex(value => value.id === e.target.getAttribute('id'))
      if(index === -1) return undefined;
      const temp = this.item[index];
      await axios.put(`/items/${temp.id}`, {
        text: e.target.value
      });
    },
    async addSubItem(e) {
      let id = e.target.id
      const indexElement = this.item.findIndex((value => value.id === id));
      if(indexElement === -1) return undefined;
      const temp = (await axios.post(`/items/${this.padId}`, {text: '', mainId: id})).data
      this.items[indexElement].subItem.push(temp);

    },
    async deleteSubItem(e){
      const id = e.target.id;
      const index = this.items.findIndex(value => value.id === id)
      if(index === -1) return undefined;
      const temp = await axios.delete(`/items/${id}`);
      if (temp.status === 200)
        this.items.splice(index, 1)
    },

    async addItem(e){
      let id = e.target.id
      const indexElement = this.item.findIndex((value => value.id === id));
      if(indexElement === -1) return undefined;
      const temp = (await axios.post(`/items/${this.padId}`, {text: '', mainId: this.items[indexElement].mainId})).data
      this.items.push(temp);
    },

    async nextSubFocused(e){
      e.preventDefault();
      const index = this.items.findIndex((value) => value.id === e.target.id);
      try {
        this.$refs[`textarea_sub_${this.items[index+1].id}`][0].focus()
      }catch (e) {
        return
      }

    },
    async prevSubFocused(e){
      e.preventDefault();
      const index = this.items.findIndex((value) => value.id === e.target.id);
      if(!this.items[index-1]) return false;
      this.$refs[`textarea_sub_${this.items[index-1].id}`][0].focus()
    },

    async upSubItem(e){
      e.preventDefault();
      let index = this.items.findIndex((value) => value.id === e.target.id);
      if(!this.items[index-1]) return false;
      const prevIndex = index - 1;
      const temp = await axios.patch(`/items/${this.items[index].id}`, {
        sortBeforeId: this.items[prevIndex].id
      });
      if(temp.status === 200) {
        const temp = this.items[index];
        this.items[index] = this.items[prevIndex];
        this.items[prevIndex] = temp

        await this.$refs[`textarea_sub_${this.items[index].id}`][0].focus()

      }

    },
    async downSubItem(e){
      e.preventDefault();
      const index = this.items.findIndex((value) => value.id === e.target.id);
      if(!this.items[index+1]) return false;
      const prevIndex = index + 1;
      const temp = await axios.patch(`/items/${this.items[index].id}`, {
        sortAfterId: this.items[prevIndex].id
      });
      if(temp.status === 200){
        const temp = this.items[index];
        this.items[index] = this.items[prevIndex];
        this.items[prevIndex] = temp;

      }

    },
  }
}
</script>

<style scoped>

</style>