<template>
  <div class="row">
      <div v-for="elem in news" :key="elem.id">
        <div class="card" v-if="elem.id" @click="open(elem.id)">
          <div class="card-content black-text">
            <span class="card-title">{{elem.title}}</span>
            <p>{{elem.text.replace(/<[^>]*>?/gm, '').slice(0,140)}}...</p>
          </div>
        </div>
      </div>
  </div>

  <div ref="modal_news" class="modal ">
    <div class="modal-header">
      <h2>{{modal.header}}</h2>
    </div>
    <div class="modal-content" v-html="modal.content">

    </div>
  </div>

  <div class="modal-overlay" ref="modal_news__overlay" @click="close"></div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewsComponent",
  data(){
    return{
      news: [{
        id: '',
        title: '',
        text: '',
        read: ''
      }],
      modal: {
        header: '',
        content: '',
      }
    }
  },
  async mounted(){
    this.news = (await axios.get('/news')).data

  },
  methods: {
    close(){
      this.$refs.modal_news.classList.remove('open');
      this.$refs.modal_news__overlay.classList.remove('open');
      this.modal = {
        header: '',
        content: '',
      }
    },
    open(e){
      const temp = this.news.find((value => value.id === e))
      this.modal = {
        header: temp.title,
        content: temp.text,
      }
      this.$refs.modal_news.classList.add('open');
      this.$refs.modal_news__overlay.classList.add('open');
    }
  }
}
</script>

<style scoped>
.card{
  cursor: pointer;
}

.modal.open{
  z-index: 4001;
  display: block;
  opacity: 1;
  top: 10vh;
  transform: scaleX(1) scaleY(1);
  min-width: 600px;
  width: 80vw;
  min-height: 60vh;
  max-height: 80vh;
}
.modal-overlay.open{
  z-index: 1002;
  display: block;
  opacity: 0.5;
}
.modal_bug{
  display: flex;
}
.modal-header{
  text-align: center;
}
</style>