<template>
<h1>qr report</h1>
  <form class="picker card-panel"  @change="changeForm()">
    <div class="picker_start">
      <label for="start">Start:
      <input type="date" id="start" name="start" v-model="form.dateStart">
      </label>
    </div>
    <div class="picker_end">
      <label for="end">End:
      <input type="date" id="end" name="end" v-model="form.dateEnd">
      </label>
    </div>
    <div class="picker_user">
      <label>
        <span>Create User</span>
        <select ref="userPickerFilter" @change="changeUname($event)">
          <option selected disabled :value="undefined">Create user</option>
          <option v-for="user in filter.user" :key="user.userId" :value="user.create_user_id">{{user.username ?? user.mail}}</option>
        </select>
      </label>
    </div>
  </form>

  <div class="bugs_list" v-if="bugs.opened || bugs.updated">
    <div class="bugs_list__opened card-panel">
      <h5 class="header_list">Opened</h5>
      <div class="collection">
        <div class="collection-item" v-for="bug in bugs.opened" :key="bug.id" @click="openBug(bug.short_name)">
          <div class="item_top">
            <div>
              <span class="text-lighten-1">{{bug.short_name ?? ''}}</span>
            </div>
            <div>
              <span class="bug_state " :class="bug.state.toLowerCase()">{{bug.state}}</span>
            </div>
          </div>
          <div>
            {{bug.title!=="" ?bug.title: 'not text'}}
          </div>
        </div>
      </div>

      <h5 class="header_list point" @click="updateShow = !updateShow">Updated</h5>
      <div class="collection" v-if="updateShow">
        <div class="collection-item" v-for="bug in bugs.updated" :key="bug.id" @click="openBug(bug.short_name)">
          <div class="item_top">
            <div>
              <span class="text-lighten-1">{{bug.short_name ?? ''}}</span>
            </div>
            <div>
              <span class="bug_state " :class="bug.state.toLowerCase()">{{bug.state}}</span>
            </div>
          </div>
          <div>
            {{bug.title!=="" ?bug.title: 'not text'}}
          </div>
        </div>
      </div>
    </div>
    <div class="chart card-panel">
      <statistic-pie-chart :data-chart="dataChartBugs" v-if="dataChartBugs"></statistic-pie-chart>
    </div>
  </div>



</template>

<script>
import axios from "axios";
import M from 'materialize-css'
import StatisticPieChart from "@/components/statisctic/chart/StatisticPieChart.vue";

export default {
  name: "QAReport",
  components: {StatisticPieChart},
  data(){
    return{
      updateShow: false,
      form: {
        dateStart: undefined,
        dateEnd: undefined,
        createUser: undefined
      },
      spaceId: undefined,
      bugs:[],
      filter: {},
      userPickerFilter: undefined,
      actionBtn: undefined,
      dataChartBugs: undefined
    }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  async mounted() {
    this.filter = await this.getFilter();

  },
  updated(){
    this.filterUserPicker = M.FormSelect.init(this.$refs.userPickerFilter, {isMultiple: false});
  },
  methods:{
    getBugs(){
      axios.get(`/qa-report/bug-list`, {
        params:{
          space_id: this.spaceId,
          date_start: this.form.dateStart,
          date_end: this.form.dateEnd,
          create_user: this.form.createUser
        }
      }).then(response => {
        if(response.status !== 200) return;
        this.bugs = response.data
        let tmp = this.bugs.count
        this.dataChartBugs = {
          labelList: ['open', 'reopen', 'fixed', 'closed', 'hold'],
          colorList: ['#15d1d1', '#e6003a', '#e6bb00', '#07d401', '#26a69a'],
          dataList: [tmp.open, tmp.reopen, tmp.fixed, tmp.closed, tmp.hold]
        }
      });

    },
    async getFilter(){
      let temp = await axios.get(`/qa-report/users`, {params: {space_id: this.spaceId}})
      if(temp.status !== 200)
        return []
      return {user: temp.data}
    },
    changeForm(){
      if(this.form.dateStart !== undefined &&
          this.form.dateEnd !== undefined &&
          this.form.createUser !== undefined
      ){
        this.getBugs()
      }
    },
    changeUname(e){
      this.form.createUser = e.target.value;
    },
    openBug(shortName){
      if(shortName && shortName.length === 0) return;
      window.open(`/space/${this.spaceId}#bugs?shortName=${shortName}`, '_blank');
      console.log(shortName)
    }
  }
}
</script>

<style scoped lang="scss">
.collection-item{
  cursor: pointer;
  .text-lighten-1{
    color: #26a69a
  }
  .bug_state{
    &.fixed{
      color: #e6bb00;
    }
    &.closed{
      color: #07d401;
    }
    &.open{
      color: #15d1d1;
    }
    &.reopen{
      color: #e6003a;
    }
    &.ready{
      color: #ffea5f;
    }
  }


}
.header_list{
  text-align: center;
  margin: 0;
  &.point{
    cursor: pointer;
  }
}
.chart{
  margin-left: 10px;
  width: 50%;
  max-height: 340px;
}
.bugs_list{
  display: flex;
  &__opened{
    width: 50%;
    margin-right: 10px;
  }

  .item_top{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
.picker{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  &_start{

  }
}

</style>
