<template>
  <div class="card-panel testcase" :id="cases.id" v-if="cases.id">
    <div class="card-content">
      <div class="test_case__link-edit_main">
        <a class="open_link" target="_blank" rel="noopener noreferrer" :href="cases.link">
          <i class="material-icons ">open_in_new</i>
        </a>

        <a class="waves-light test_case__link-edit" @click="this.$refs.caseLink.disabled = !this.$refs.caseLink.disabled">
          <i class="material-icons ">link</i>
        </a>

        <label ><input type="text" placeholder="link" ref="caseLink" disabled :value="cases.link"></label>
      </div>


      <label><input type="text" placeholder="title" ref="caseTitle" :value="cases.title"></label>

      <div class="case_description" v-if="textEditor">
      <span class="title_case-editor">Description </span>
        <BubbleMenuComponent :editor="textEditor" :testcase="true" :testcaseId="cases.id"/>
      </div>

      <div class="case_steps" v-if="stepsEditor">
        <span class="title_case-editor ">Steps </span>
        <div class="editor">
          <BubbleMenuComponent :editor="stepsEditor" :testcase="true" :testcaseId="cases.id"/>
        </div>
      </div>

      <div class="case_expected" v-if="expectedResEditor">
        <span class="title_case-editor"> Expected results </span>
        <BubbleMenuComponent :editor="expectedResEditor" :testcase="true" :testcaseId="cases.id"/>
      </div>

      <div class="row-reverse" v-if="cases?.id">
        <a class="btn waves-light red" @click="deleteCase">
          delete
          <i class="material-icons right">delete</i>
        </a>

        <a class="btn waves-light" @click="saveCase">
          save
          <i class="material-icons right">save</i>
        </a>
      </div>
    </div>
  </div>


</template>

<script>
import {Editor} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Image from '@tiptap/extension-image'
import Link from "@tiptap/extension-link";
import BubbleMenuComponent from "@/components/editor/menu/BubbleMenuComponent";
import axios from "axios";

export default {

  name: "TestcasesRightComponent",
  emits: ["update-case", "delete-case"],
  components: {
    BubbleMenuComponent
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  props: {
    cases: {
      type: Object,
      required: true,
    },
  },
  data(){
    return{
      textEditor: undefined,
      stepsEditor: undefined,
      expectedResEditor: undefined,
      caseTitle: '',
      showLink: false,
    }
  },
  mounted() {
    this.textEditor = this.creatEditor();
    this.stepsEditor = this.creatEditor();
    this.expectedResEditor = this.creatEditor();
    this.caseTitle = this.cases.title;
  },
  updated() {

    this.textEditor.commands.setContent(this.cases.text);
    this.stepsEditor.commands.setContent(this.cases.steps);
    this.expectedResEditor.commands.setContent(this.cases.expected_results);

  },
  methods:{
    creatEditor(){
      return new Editor({
        editorProps: {
          attributes: {
            class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
          },
        },
        extensions: [StarterKit,
          Image.configure({
            allowBase64: true,
            inline: true
          }),
          Link.configure({
            target: null,
            linkOnPaste: true,
            openOnClick: true,
          })],
        content: '',
      });
    },
    saveCase(){
      axios.patch(`/testcases/${this.cases.id}`, {
        link: this.$refs.caseLink.value,
        title: this.$refs.caseTitle.value,
        text: this.textEditor.getHTML(),
        steps: this.stepsEditor.getHTML(),
        expected_results: this.expectedResEditor.getHTML(),
      }).then(
        this.$emit('update-case')
      )
    },
    deleteCase(){
      axios.delete(`/testcases/${this.cases.id}`)
          .then(
              this.$emit('delete-case', this.cases.id)
          );
    }
  }
}
</script>

<style lang="scss">
.test_case__link-edit_main{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  .test_case__link-edit{
    color: #26a69a;
    cursor: pointer;
  }
  label{
    width: 100%;
  }
  .open_link{
    color: #26a69a;
    cursor: pointer;

  }
}

.card-panel.testcase{
  max-height: 98vh;
  overflow-y: auto;
  margin: 0;
}
.row-reverse{
  display: flex;
  margin-top: 15px;
  flex-direction: row;
  justify-content: space-between;
}
.title_case-editor{
  font-size: 11px;
  color: #8d8d8d;
}
.prose {
  p{
    margin: 0;
  }
  min-height: 100px;
  max-height: 33vh;
  overflow-y: auto;
  border: 1px solid black;
  img{
    height: 128px;
  }
  .image-resizer {
    display: inline-flex;
    position: relative;
    flex-grow: 0;
    .resize-trigger {
      position: absolute;
      right: -6px;
      bottom: -9px;
      opacity: 0;
      transition: opacity .3s ease;
      color: #3259a5;
    }
    &:hover .resize-trigger {
      opacity: 1;
    }
  }
}
</style>