<template>
  <div class="profile_header ">
    <img class="circle" :src="userAvatar" style="width: 64px; height: 64px" @click="openFileUpload">
    <input type="file" ref="fileInput" style="display: none" multiple accept="image/jpeg, image/png" @change="uploadFile">
    <h4>Profile</h4>
  </div>
  <div class="card-panel">
    <label><input v-model="username" placeholder="username" /></label>
    <label><input :value="user.mail" disabled ></label>

    <button class="btn waves-light" type="submit" name="action" @click="saveUser">save
      <i class="material-icons right">save</i>
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
name: "ProfileComponent",
  data(){
    return{
      username: '',
      userAvatar: '',
      mail: '',
      user: {
        id: '',
        username: '',
        mail: ''
      },
    }
  },
  async mounted() {
    this.user = (await axios.get('/user')).data;
    this.userAvatar = this.user.avatar.filepath;
    this.username = this.user.username;
  },
  methods: {
    async saveUser(){
      this.user = (await axios.patch('/user', {
        username: this.username
      })).data
    },
    async openFileUpload(){
      this.$refs.fileInput.click()
    },
    async uploadFile(){
      if (!this.$refs.fileInput.files[0]) return;
      const form = new FormData();
      form.append('file', this.$refs.fileInput.files[0]);
      const temp = (await axios.post('/file', form, {headers: {"Content-Type": "multipart/form-data",}})).data;
      if (temp.id){
        this.$router.go(this.$router.currentRoute)
      }
    }
  }
}
</script>

<style scoped>
.profile_header{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.profile_header img{
  margin-right: 15px;
}
</style>