import { createWebHistory, createRouter } from "vue-router";
import VTPad from "@/components/pads/vtpad/VTPad";
import RunComponent from "@/components/pads/run/RunComponent";
import SpaceComponent from "@/components/SpaceComponent";
import AuthComponent from "@/components/AuthComponent";
import RegisterComponent from "@/components/RegisterComponent";
import ProfileComponent from "@/components/settings/ProfileComponent";
import SpaceSettingsComponent from "@/components/settings/SpaceSettingsComponent";
import PadMainComponent from "@/components/pads/PadMainComponent";
import NotificationPageComponent from "@/components/notification/NotificationPageComponent";
import SpaceStatisticComponent from "@/components/statisctic/SpaceStatisticComponent";
import NewsComponent from "@/components/news/NewsComponent";
import NewsDetailComponent from "@/components/news/NewsDetailComponent";
import ReportComponent from "@/components/report-portal/ReportComponent";
import TestcasesListComponent from "@/components/pads/testcases/TestcasesListComponent.vue";
import ReportCoverageComponent from "@/components/report-portal/ReportCoverageComponent.vue";
import QAReport from "@/components/qa-report/QAReport.vue";

const routes = [
    {
        path: "/space",
        name: "PadComponent",
        children: [
            {
                path: ':spaceId',
                component: PadMainComponent,
            },
            {
                path: ':spaceId/testcases',
                component: TestcasesListComponent
            },
            {
                path: ':spaceId/report-qa',
                component: QAReport
            }
        ]
    },
    {
        path: "/pad",
        name: "VTPad",
        children: [
            {
                path: ':padId',
                name: 'pad',
                component: VTPad,
            }
        ]

    },
    {
        path: "/runs",
        name: "RunComponent",
        children: [
            {
                path: ':runId',
                component: RunComponent,
            }
        ]
    },
    {
        path: "/spaces",
        name: "SpaceComponent",
        component: SpaceComponent,
    },
    {
        path: "/auth",
        name: "auth",
        component: AuthComponent

    },
    {
        path: '/register',
        name: 'register',
        component: RegisterComponent
    },
    {
      path: '/settings',
      name: 'settings',
      children: [
          {
              path: 'profile',
              name: 'settingsProfile',
              component: ProfileComponent,
          },
          {
              path: 'space/:spaceId',
              name: 'settingsSpace',
              component: SpaceSettingsComponent,
          },

      ]
    },
    {
      path: '/statistics/space/:spaceId',
      name: 'statisticSpace',
      component:  SpaceStatisticComponent
    },
    {
        path: '/report/space/:spaceId',
        name: 'reportStatisticSpace',
        component:  ReportComponent,
    },
    {
        path: '/report/space/:spaceId/coverage',
        name: 'reportCoverage',
        component: ReportCoverageComponent,
    },
    {
        path: '/notification',
        name: 'notification',
        component: NotificationPageComponent
    },
    {
        path: '/news',
        name: 'news',
        component: NewsComponent,
    },
    {
        path: '/news/:newsId',
        name: 'newsDetail',
        component: NewsDetailComponent
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
