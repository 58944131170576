<template>
  <div class="card-panel ">

    <div class=" filter">
      <div class="filter_select">
        <label>
  <!--        <input type="text" class="datepicker" ref="datePickerFilter">-->
          <span v-if="this.filterParam.create_date">Create date</span>
          <select  class="" ref="datePickerFilter" @change="reset($event, 'createDate')">
            <option value=""  selected >Create date</option>
            <option  v-for="createDate in filter.create_date" :key="createDate" :value="createDate.split('T')[0]">{{createDate.split('T')[0]}}</option>
          </select>
        </label>

        <label>
          <span v-if="this.filterParam.create_user">Create User</span>
          <select  class="" ref="userPickerFilter" @change="reset($event, 'createUser')">
            <option value=""   selected>Create User</option>
            <option  v-for="user in filter.user" :key="user.userId" :value="user.userId">{{user.username ?? user.mail}}</option>
          </select>
        </label>

        <label >
          <span v-if="this.filterParam.assigner_id">Assigner</span>
          <select  class="" ref="assignerPickerFilter" @change="reset($event, 'assignerUser')" multiple>
            <option value="" disabled >Assigner</option>
            <option  v-for="user in filter.user" :key="user.userId" :value="user.userId">{{user.username ?? user.mail}}</option>
          </select>
        </label>

        <label>
          <span v-if="this.filterParam.state">State</span>
          <select multiple class="" ref="statePickerFilter" @change="reset($event, 'state')">
            <option value=""  disabled>State</option>
            <option  v-for="state in filter.state" :key="state" :value="state">{{state}}</option>
          </select>
        </label>

        <label>
          <span v-if="this.filterParam.external_link">External task</span>
          <select  class="" ref="externalTaskPickerFilter" @change="reset($event, 'external_link')">
            <option value="" selected>External task</option>
            <option  v-for="task in filter.external_link" :key="task" :value="task">{{task}}</option>
          </select>
        </label>

        <label>
          <span v-if="this.filterParam.estimate_date">Estimate</span>
          <select  class="" ref="estimatePickerFilter" @change="reset($event, 'estimateDate')">
            <option value=""  selected>Estimate</option>
            <option  v-for="estimate_date in filter.estimate_date" :key="estimate_date" :value="estimate_date.split('T')[0]">{{estimate_date.split('T')[0]}}</option>
          </select>
        </label>

        <label>
          <span v-if="this.filterParam.tag">Tag</span>
          <select  class="" ref="tagPickerFilter" @change="reset($event, 'tag')">
            <option value="" selected>Tag</option>
            <option  v-for="tag in filter.tag" :key="tag.userId" :value="tag.id">{{tag.title}}</option>
          </select>
        </label>

        <label>
          <span v-if="this.filterParam.order_by">Order By</span>
          <select class="" ref="orderByPickerFilter" @change="reset($event, 'orderBy')">
            <option value=""  >Order By</option>
            <option  v-for="order in filter.order_by" :key="order" :value="order">{{order}}</option>
          </select>
        </label>

      </div>

      <div class="filter_checkbox">
        <label>
          <input type="checkbox" ref="notAssignerFilter" @change="reset($event, 'notAssignerFilter')" >
          <span>Not Assigner</span>
        </label>



        <label class="with_icon" @click="reset($event, 'arrowOrderFilter')" :class="this.filterParam.order_arrow.toLowerCase()">
          <i class="material-icons">filter_list</i>
          <span >Arrow Order </span>
        </label>
      </div>


      <a class="btn-floating btn-large  teal lighten-1 btn_newBug" @click="addNewBug()"><i class="material-icons">add</i></a>
    </div>
  </div>

  <div class="card" v-if="bugs.length > 0">
    <div class="collection">
      <div class="collection-item avatar" v-for="bug in this.bugs" :key="bug.id" @click="show(bug.id)">
        <div class="left-content">
          <div class="left-content__avatar">
            <div class="short_name_row">
              <span class="short_name text-lighten-1">{{bug.short_name}}</span>
              <span class="short_name-external_task text-lighten-1">{{bug.external_link?.task}}</span>



            </div>
            <div v-if="bug.assigner_user">
              <img :src="bug.assigner_user.avatar_filepath" :alt="bug.assigner_user.username ? sliceUserName(bug.assigner_user.username): ''" class="circle" v-if="bug.assigner_user.avatar_filepath">
              <div v-else class="circle no-avatar"><p >{{bug.assigner_user.username ? sliceUserName(bug.assigner_user.username).toUpperCase() : ''}}</p></div>
            </div>
            <div v-else class="circle no-avatar"></div>
          </div>
        <span class="title">{{ (bug.title && bug.title !== '') ? sliceBugTitle(bug.title) : sliceBugTitle(cutTeg(bug.text))}}</span>
        </div>

        <div class="secondary-content in_row">
          <div class="tag" v-if="bug.tag.length > 0">
            <div class="chip" v-for="tag in bug.tag" :key="tag.id" :style="{backgroundColor: tag.color}">{{tag.title}}</div>
          </div>
          <div class="estimate_date" v-if="bug.estimate_date">{{bug.estimate_date.split('T')[0]}}</div>

          <div class="bug_state" :class="bug.state.toLowerCase()">{{bug.state}}</div>

          <img :src="bug.create_user.avatar_filepath" :alt="sliceUserName(bug.create_user.username).toUpperCase()" class="my_circle" v-if="bug.create_user.avatar_filepath">

          <div v-else class="create_user__avatar no-avatar"><p>{{sliceUserName(bug.create_user.username).toUpperCase()}}</p></div >

        </div>
      </div>


    </div>
  </div>

  <div class="collection card">
    <div class="collection-item show_more" @click="addSkip">
      <span class="title">show more</span>
    </div>
  </div>
  <div ref="modal_bug" class="modal ">
    <div class="modal-header">
      <div class="modal-header_element">

        <div class="modal-header_short__name">
          <p>{{'short_name' in bugModal ? bugModal.short_name : ''}}</p>
          <p v-if="bugModal.external_link"><a target="_blank" rel="noopener noreferrer" :href="bugModal.external_link?.link">{{bugModal.external_link?.task}}</a></p>
        </div>

        <img :src="bugModal.assigner_avatar" class="circle" v-if="bugModal.assigner_avatar">
        <div v-else class="modal-header_assigner circle no-avatar">
          <p>{{bugModal.assigner_user?.username.slice(0,2).toUpperCase()}}</p>
        </div>

        <label>
          <span>Assigner</span>
          <select class="" ref="assignerPickerFilterModal" >
            <option value="" selected>Assigner</option>
            <option  v-for="user in filter.user" :key="user.userId" :value="user.userId" >{{user.username ?? user.mail}}</option>
          </select>
        </label>
      </div>


      <div class="modal-header_element">
        <label>
          <span>Link</span>
          <input type="text" ref="externalLinkPickerModal" disabled :value="bugModal.external_link?.link">
        </label>
        <a class="link_edit" @click="editExternalLink"><i class="material-icons">create</i></a>
        <a class="open_link" target="_blank" rel="noopener noreferrer" :href="bugModal.external_link?.link"><i class="material-icons">open_in_new</i></a>
      </div>

      <div class="modal-header_element">
        <div class="modal-header_state">
          <label>
            <span>State</span>
            <select  ref="statePickerModal" @change="editState_bugModal($event)">
              <option v-for="state in filter.state" :key="state" :value="state" >{{state}}</option>
            </select>
          </label>
        </div>

        <label>
          <span>Estimate date</span>
          <input type="text" class="datepicker" :value="bugModal.estimate_date ? bugModal.estimate_date.split('T')[0] : null" ref="datepickerModal">
        </label>

      </div>
    </div>
    <div class="modal-content">
      <div class="tab_bug__modal-mobile">
        <a class="" @click="showBugTab('text')" >Text</a>
        <a class=""  @click="showBugTab('comments')"> Comments</a>
      </div>
      <div class="modal_left" ref="modal_left"  >
        <div class="modal_left__title">
          <div class="modal_left__title_text">
            <label>
              <input class="modal_title" ref="modalTitleRef"  v-model="bugTitle"/>
            </label>
          </div>

          <div class="modal_left__title_estimate-date">

          </div>
          <div class="modal_left__title_assigner-user">

          </div>
        </div>
        <span>Text:</span>
        <div class="card modal_left__text">
          <div class="card-content">
            <BubbleMenuComponent :editor="bugText" v-if="bugText"/>
          </div>
        </div>

        <span>Steps:</span>
        <div class="card modal_left__steps">
          <div class="card-content">
            <BubbleMenuComponent :editor="bugSteps"  v-if="bugSteps" />
          </div>
        </div>


        <span>Additional link</span>
        <div class="card modal_left__additional-link">
          <div class="card-content">
            <BubbleMenuComponent :editor="bugAdditionalLink"  v-if="bugAdditionalLink"/>
          </div>
        </div>

      </div>
      <div class="modal_right __mobile" ref="modal_right">
        <div class="modal_right__comments">
          <PadBugCommentsComponent :bug="bugModal.id" v-if="bugModal.id"></PadBugCommentsComponent>
        </div>
      </div >
    </div>
    <div class="modal-footer">
      <div class="modal-footer_left">
        <label>
          <select class="" ref="tagPickerModal" @change="addTagToBug($event, bugModal.id)">
            <option value="" selected>Tag</option>
            <option  v-for="tag in filter.tag" :key="tag.userId" :value="tag.id">{{tag.title}}</option>
          </select>
        </label>

        <div class="tag" v-if="bugModal.tag && bugModal.tag.length > 0" >
          <div class="chip" v-for="tag in bugModal.tag" :key="tag.id" :style="{backgroundColor: tag.color}">
            {{tag.title}}
            <i class="close material-icons" @click="deleteTagFromBug(bugModal.id, tag.id)">close</i>
          </div>
        </div>
      </div>

      <div class="modal-footer_right">
      <a  class="btn waves-light" @click="close">cancel
        <i class="material-icons right">cancel</i>
      </a>
      <a  class="btn waves-light" @click="saveBug">save
        <i class="material-icons right">save</i>
      </a>

      </div>

    </div>
  </div>

  <div class="modal-overlay" ref="modal_bug__overlay" @click="close"></div>
</template>


<script>

import M from 'materialize-css'
import { DateTime } from "luxon";


import axios from 'axios'
import StarterKit from '@tiptap/starter-kit'
import { Editor  } from '@tiptap/vue-3'
import Link from '@tiptap/extension-link'
import BubbleMenuComponent from "@/components/editor/menu/BubbleMenuComponent";
import PadBugCommentsComponent from "@/components/pads/bugs/PadBugCommentsComponent";
import qs from 'qs'
import Image from "@tiptap/extension-image";

export default {
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;

      },
    },
  },
  components: {
    PadBugCommentsComponent,
    BubbleMenuComponent,

  },
  name: "PadBugsComponent",
  data(){
    return {
      bugs: [],
      spaceId: '',
      bugModal: {},
      bugTitle: null,
      bugText: null,
      bugSteps: null,
      bugAdditionalLink: null,
      filter: {},
      filterParam: {
        space_id: '',
        create_date: undefined,
        create_user: undefined,
        assigner_id: undefined,
        state: undefined,
        estimate_date: undefined,
        not_assigner: false,
        limit: undefined,
        skip: undefined,
        order_by: undefined,
        order_arrow: 'DESC',
        tag: undefined,
        external_link: undefined
      },
      filerInstance: {
        filterDatePicker: undefined,
        filterUserPicker: undefined,
        filterAssignerPicker: undefined,
        filterStatePicker: undefined,
        filterEstimatePicker: undefined,
        filterOrderByPicker: undefined,
        filterTagPicker: undefined,
        externalTaskPickerFilter: undefined
      }
    }
  },

  beforeUpdate() {

  },
  async mounted(){

    // this.spaceId = localStorage.getItem('spaceId');
    let shortNameId = undefined;
    console.log(this.$route);
    this.filterParam.limit = 20;
    this.filterParam.skip = 0;
    if (this.$route.hash.split('?')[1]){
      shortNameId = (await axios.get('/bugs/id/by-short', {params: {space_id: this.spaceId, short_name: this.$route.hash.split('?')[1].split('=')[1]}})).data

    }

    this.filterParam.space_id = this.spaceId
    this.getBugs().then()

    this.filter = (await axios.get(`/bugs/filters?space_id=${this.spaceId}`)).data;

    if (shortNameId) await this.show(shortNameId)
  },

  updated() {
    this.filerInstance.filterDatePicker = M.FormSelect.init(this.$refs.datePickerFilter, {isMultiple: true});
    this.filerInstance.filterUserPicker = M.FormSelect.init(this.$refs.userPickerFilter, {isMultiple: true});
    this.filerInstance.filterAssignerPicker = M.FormSelect.init(this.$refs.assignerPickerFilter, {isMultiple: true});
    this.filerInstance.filterStatePicker = M.FormSelect.init(this.$refs.statePickerFilter, {isMultiple: true});
    this.filerInstance.filterEstimatePicker = M.FormSelect.init(this.$refs.estimatePickerFilter, {isMultiple: true}); // orderByPickerFilter
    this.filerInstance.filterOrderByPicker = M.FormSelect.init(this.$refs.orderByPickerFilter, {isMultiple: true}); // orderByPickerFilter
    this.filerInstance.filterTagPicker = M.FormSelect.init(this.$refs.tagPickerFilter, {isMultiple: true});
    this.filerInstance.externalTaskPickerFilter = M.FormSelect.init(this.$refs.externalTaskPickerFilter, {isMultiple: false});
    // tagPickerExternalTask


  },
  methods: {
    sliceUserName(name){
      try{
        return name.slice(0, 2);
      }catch (e) {
        return ''
      }
    },
    sliceBugTitle(title){
      try{
        return title.slice(0, 60);
      }catch (e) {
        return ""
      }
    },
    showBugTab(tab){
      switch (tab) {
        case 'comments':
          this.$refs.modal_right.classList.toggle('__mobile')
          this.$refs.modal_left.classList.toggle('__mobile')
          break;
        case 'text':
          this.$refs.modal_right.classList.toggle('__mobile')
          this.$refs.modal_left.classList.toggle('__mobile')
          break;
      }
    },
    async updateBug(save=false) {
      const tempSkip = this.filterParam.skip;
      const tempLimit = this.filterParam.limit;
      if (save && this.filterParam.skip !== 0){
        this.filterParam.skip = 0;
        this.filterParam.limit = tempSkip + tempLimit;
      }
      console.log(tempSkip, tempLimit);
      await this.getBugs()
      this.filter = (await axios.get(`/bugs/filters?space_id=${this.spaceId}`)).data;
      if (save){
        this.filterParam.skip = tempSkip;
        this.filterParam.limit = tempLimit;
      }
    },
    async reset(e, filterName){
      switch (filterName) {
        case 'createDate':
            this.filterParam.create_date = e.target.value ? e.target.value : undefined;
        break;
        case 'createUser':
            this.filterParam.create_user = e.target.value ? e.target.value : undefined;
        break;
        case 'assignerUser':
          this.filterParam.assigner_id = this.filerInstance.filterAssignerPicker.getSelectedValues().length > 0 ?this.filerInstance.filterAssignerPicker.getSelectedValues() : undefined;
        break;
        case 'state':
          // this.filterParam.state = e.target.value ? e.target.value.toUpperCase() : undefined;
            if (this.filerInstance.filterStatePicker.getSelectedValues().length > 0) {
              const temp = this.filerInstance.filterStatePicker.getSelectedValues().map((val) => val.toUpperCase())

              this.filterParam.state = temp ?? undefined
            }else{
              this.filterParam.state =  undefined
            }

        break;
        case 'estimateDate':
          this.filterParam.estimate_date = e.target.value ? e.target.value : undefined;
        break;
        case 'notAssignerFilter':
          this.filterParam.not_assigner = e.target.checked;
        break;

        case 'orderBy':
          this.filterParam.order_by = e.target.value ? e.target.value : undefined;
        break;
        case 'arrowOrderFilter':
          e.target.parentElement.classList.remove(this.filterParam.order_arrow.toLowerCase());
          this.filterParam.order_arrow = this.filterParam.order_arrow === 'ASC' ? 'DESC' : this.filterParam.order_arrow === 'DESC' ? 'ASC' : 'DESC';
          e.target.parentElement.classList.add(this.filterParam.order_arrow.toLowerCase());
        break;
        case 'tag':
          this.filterParam.tag = e.target.value;
        break;
        case 'external_link':
          this.filterParam.external_link = e.target.value;
        break;

      }

      await this.getBugs()
    },
    async getBugs(append=false){
      if (!append) {
        this.filterParam.skip = 0;
        axios.get(`/bugs?${qs.stringify(this.filterParam, {arrayFormat: "repeat"})}`).then(res=>{
          this.bugs = res.data
        })
        return
        // this.bugs = (await axios.get(`/bugs?${qs.stringify(this.filterParam, {arrayFormat: "repeat"})}`)).data;
      }
      else{
        try {
          const temp = (await axios.get(`/bugs?${qs.stringify(this.filterParam, {arrayFormat: "repeat"})}`)).data
          if (temp.length === 0 && this.filterParam.skip > 0) this.filterParam.skip -= 20
          for (const elem of temp){
            this.bugs.push(elem);
          }
        }catch (e) {
         return;
        }
      }
    },
    async addSkip(){
      this.filterParam.skip += 20;
      await this.getBugs(true);
    },
    async show(id){
      if (!id){
        this.bugTitle = '';
        this.bugText = new Editor({
          extensions: [StarterKit,
            Link.configure({
            target: null,
            linkOnPaste: true,
            openOnClick: true,
          })],
          content: ''
        });

        this.bugSteps = new Editor({
          extensions: [StarterKit,
            Link.configure({
              target: null,
              linkOnPaste: true,
              openOnClick: true,
            })],
          content: ''
        });

        this.bugAdditionalLink = new Editor({
          extensions: [StarterKit,
            Link.configure({
              target: null,
              linkOnPaste: true,
              openOnClick: true,
            })],
          content: ''
        });


        this.$refs.modal_bug.classList.add('open');
        this.$refs.modal_bug__overlay.classList.add('open');
        this.$refs.statePickerModal.value = 'Open'
        M.FormSelect.init(this.$refs.statePickerModal); //
        M.FormSelect.init(this.$refs.assignerPickerFilterModal); //
        this.$refs.externalLinkPickerModal.disabled = false;

        return
      }

      this.bugModal = (await axios.get(`/bugs/detail/${String(id)}`)).data//this.bugs.filter((value => value.id === String(id)))[0] ;
      if(this.bugModal.short_name){
        this.$router.push(`${this.$route.hash.split('?')[0]}?shortName=${this.bugModal.short_name}`)
      }
      this.bugTitle = this.bugModal.title;
      this.bugText = new Editor({
        extensions: [StarterKit,
          Image.configure({
            allowBase64: true,
            inline: true
          }),
          Link.configure({
            target: null,
            linkOnPaste: true,
            openOnClick: true,
          })],
        content: this.bugModal.text
      });
      this.bugText.on('blur', ()=>{this.saveBug(false)});

      this.bugSteps = new Editor({
        extensions: [StarterKit,
          Image.configure({
            allowBase64: true,
            inline: true
          }),
          Link.configure({
            target: null,
            linkOnPaste: true,
            openOnClick: true,
          })],
        content: this.bugModal.steps
      });
      this.bugSteps.on('blur', ()=>{this.saveBug(false)});

      this.bugAdditionalLink = new Editor({
        extensions: [StarterKit,
          Image.configure({
            allowBase64: true,
            inline: true
          }),
          Link.configure({
            target: null,
            linkOnPaste: true,
            openOnClick: true,
          })],
        content: this.bugModal.additional_link
      });
      this.bugAdditionalLink.on('blur', ()=>{this.saveBug(false)});




      this.$refs.modal_bug.classList.add('open');
      this.$refs.modal_bug__overlay.classList.add('open');
      this.$refs.assignerPickerFilterModal.value = this.bugModal.assigner_id ?? null;
      this.$refs.tagPickerModal.value = ''
      this.$refs.externalLinkPickerModal.disabled = true;

      const stateTemp = this.bugModal.state.toLowerCase();
      this.$refs.statePickerModal.value = stateTemp.charAt(0).toUpperCase() + stateTemp.slice(1) ?? null;

      M.FormSelect.init(this.$refs.statePickerModal)
      M.FormSelect.init(this.$refs.assignerPickerFilterModal);
      M.FormSelect.init(this.$refs.tagPickerModal);
    },
    async close(){
      this.$refs.modal_bug.classList.remove('open');
      this.$refs.modal_bug__overlay.classList.remove('open');
      this.$refs.statePickerModal = null;
      this.bugText.destroy();
      this.bugSteps.destroy();
      this.bugAdditionalLink.destroy();
      this.bugText = null;
      this.bugSteps = null;
      this.bugAdditionalLink = null;
      this.$refs.assignerPickerFilterModal.value = null;
      this.bugModal = {};
      this.$router.push({hash: '#bugs'});
      this.$refs.tagPickerModal.value = null;
      this.$refs.externalLinkPickerModal.value = null;
    },
    async addNewBug(){
      // show(bug.id);
      const temp = await axios.post(`/bugs`, {
        "title": '',
        "text": '',
        "steps": '',
        "additional_link": '',
        "space_id": this.spaceId,
        "assigner":  null,
        "state": 'OPEN',
        "external_link": undefined
      });
      this.updateBug(true).then();
      if(temp.data.id)
        await this.show(temp.data.id)
    },
    async saveBug(closeModal=true){
      if(!this.bugModal.id){
        await axios.post(`/bugs`, {
          "title": this.bugTitle,
          "text": this.bugText.getHTML(),
          "steps": this.bugSteps.getHTML(),
          "additional_link": this.bugAdditionalLink.getHTML(),
          "space_id": this.spaceId,
          "assigner": this.$refs.assignerPickerFilterModal.value !== '' ? this.$refs.assignerPickerFilterModal.value : null,
          "state": String(this.$refs.statePickerModal.value).toUpperCase(),
          "external_link": this.$refs.externalLinkPickerModal.value ?? undefined
        });
        await this.updateBug(true);
        await this.close()
        return
      }
      await axios.put(`/bugs/${this.bugModal.id}`, {
        "title": this.bugTitle,
        "text": this.bugText.getHTML(),
        "steps": this.bugSteps.getHTML(),
        "additional_link": this.bugAdditionalLink.getHTML(),
        "assigner": (this.$refs.assignerPickerFilterModal.value && this.$refs.assignerPickerFilterModal.value !== '') ?this.$refs.assignerPickerFilterModal.value : undefined,
        "state": String(this.$refs.statePickerModal.value).toUpperCase(),
        "estimate_date": this.$refs.datepickerModal.value ? DateTime.fromISO(this.$refs.datepickerModal.value): undefined,
        "external_link": this.$refs.externalLinkPickerModal.value ?? undefined
      })
      await this.updateBug(true);
      if(closeModal) await this.close();
    },
    async editState_bugModal(e){
      return e;
    },
    async getAvatar(avatarId){
      return process.env.VUE_APP_URL_BACKEND + '/' +(await axios.get(`/file/${avatarId}`)).data.filepath
    },
     cutTeg(str) {
      if (!str) return;
      return str.replace(/( |<([^>]+)>)/ig, "");
    },
    async deleteTagFromBug(bugId, tagId){

      const temp = (await axios.delete(`/bugs/${bugId}/tag/${tagId}`));
      if (temp.status === 200){
        this.bugModal.tag.splice(this.bugModal.tag.findIndex((val)=> val.id === tagId), 1);
        this.bugs.find((value => value.id === bugId)).tag.splice(this.bugModal.tag.findIndex((val)=> val.id === tagId), 1);
      }
    },
    async addTagToBug(e, bugId){
      if (this.bugModal.tag && this.bugModal.tag.length > 0)
        if(this.bugModal.tag.findIndex((val)=> val.id === e.target.value) !== -1) return

      if(e.target.value) {

        const temp = await axios.put(`/bugs/${bugId}/tag`, {tag_id: e.target.value})
        if(temp.status === 200){
          this.bugModal.tag.push(temp.data)
          this.bugs.find((value => value.id === bugId)).tag.push(temp.data)
        }

      }
    },

    editExternalLink(){
      this.$refs.externalLinkPickerModal.disabled = false;
    }
  }
}
</script>

<style scoped>

.left-content .circle{
  position: inherit !important;
}
img, .no-avatar{
  background: #26a69a;
  text-align: center;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: none;
  width: 24px !important;
  height: 24px !important;
  font-size: 16px;
  margin: 0 15px !important;
}
.my_circle{
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
}
.collection .collection-item{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.collection .collection-item .left-content{
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.collection .collection-item.avatar{
  min-height: 40px;
  cursor: pointer;
}
.create_user__avatar{
  top: 0 !important;
  right: 0 !important;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
}
.collection .collection-item.avatar .secondary-content{
  right: 24px;

}
.estimate_date{
  top: 0 !important;
  right: 36px !important;
}

.secondary-content.in_row{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  position: initial !important;
}
.secondary-content.in_row div{
  margin: 0 10px;

}
.filter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

}
.filter_select{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: flex-end;
  width: 80%;
}
.filter_select label{
  margin: 0 15px;
}
.filter_checkbox{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
}
.filter_checkbox label{
  margin: 10px 0;
}
/*.filter select{*/
/*  display: block;*/
/*  max-height: 24px;*/
/*  padding: 0 15px;*/
/*}*/

.modal.open{
  z-index: 4001;
  display: block;
  opacity: 1;
  top: 10vh;
  transform: scaleX(1) scaleY(1);
  min-width: 600px;
  width: 80vw;
  min-height: 60vh;
  max-height: 80vh;
}
.modal-overlay.open{
  z-index: 1002;
  display: block;
  opacity: 0.5;
}
.modal_bug{
  display: flex;
}

.modal-content{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.modal_left, .modal_right{
  margin: 0 15px;
  width: 100%;
  max-height: 65vh;
  overflow-y: scroll;
}

.modal_right{
  box-shadow: inset 0 0 5px 0px rgb(0 0 0 / 14%);
}
.modal .modal-header{
  padding: 15px 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.modal .modal-header .modal-header_element{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  min-width: 100px;
  margin: 0 15px;
}
.modal-header_element .link_edit, .open_link{
  color: #26a69a;
  margin-left: 10px;
  cursor: pointer;
}
.modal-header_create_date, .modal-header_estimate__date{
  margin: 0 10px;
}
.short_name, .short_name-external_task{
  max-width: 5vw;
  text-align: left;
}
.short_name-external_task{
  color: #84e1ed ;
}
/*.short_name{*/
/*  position: absolute;*/
/*  left: 0;*/
/*  top: 0;*/
/*  font-size: 8px;*/
/*}*/
.btn_newBug {
  /*position: absolute;*/
  /*!* right: 0; *!*/
  /*left: 84vw;*/
  /*top: 84px;*/
}
.modal-footer{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 24px;
}
.modal-footer a{
  margin: 0 15px !important;
}
.modal-header .datepicker{
  margin: 0 10px 4px;
}
.secondary-content .tag{
  position: absolute;
  bottom: 0;
  right: 7vw;
  font-size: .8em;
  color: black;
}
.secondary-content .tag{
  display: flex;
}
.secondary-content .tag .chip{
  text-align: center;
  vertical-align: unset;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  height: 20px;
  border-radius: 0;
}
.modal-footer_left{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.modal-footer_left .tag div{
  /*margin: 0 5px;*/
  /*padding: 0 5px;*/
  /*border-radius: 10px;*/
  /*font-size: 1em;*/
  /*height: 1.7em;*/
}
.modal-footer_left .tag{
  display: flex;
}
.modal-footer_left .tag i{
  cursor: pointer;

}
.secondary-content .bug_state.fixed{
  color: #e6bb00;
}
.secondary-content .bug_state.closed{
  color: #07d401;
}
.secondary-content .bug_state.open{
  color: #15d1d1;
}
.secondary-content .bug_state.reopen{
  color: #e6003a;
}
.secondary-content .bug_state.ready{
  color: #ffea5f;
}

.with_icon {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font-size: inherit;
  cursor: pointer;
}
.select-wrapper{
  width: 80%;
}
.with_icon i {
  margin-right: 10px;
}
.with_icon.asc i{
  color: #26a69a;
  transform: rotate(180deg)
}
.with_icon.desc i{

}
.modal-header_short__name p{
  margin: 0;
}
.short_name_row{
  display: flex;
  flex-direction: column;
  color: #26a69a;
  width: 80px;
}
.left-content__avatar{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.tab_bug__modal-mobile{
  /*display: flex;*/
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
  color: #26a69a;
  display: none;
}
.tab_bug__modal-mobile a{
  color: #26a69a;
}
.show_more{
  height: 40px;
  cursor: pointer;
  color: #13d1d1;
  justify-content: space-evenly !important;
}
</style>
