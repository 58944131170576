<template>
  <router-link :to="'/notification'" :class="'notification-icon'">
    <span class="notification-icon_text">{{this.$store.getters.notificationCount}}</span>
    <i class="material-icons">notifications</i>
  </router-link>
</template>

<script>
import axios from "axios";
import { toast } from 'vue3-toastify';
import ring from '../../assets/ring.mp3'

let sseClient;
export default {
  name: "NotificationRingComponent",
  props: {
    user: {
      id: String
    }
  },
  data(){
    return {
      countNotification: 0,
      audio: new Audio(ring)
    }
  },
  async mounted(){

    this.updateNotificationCount();

    this.audio.volume = 0.1;

  },
  methods: {
    notification() {
      console.log()
      let eventSource = new EventSource(`${process.env.VUE_APP_URL_NOTIFICATION}/notification/${this.user.id}/stream`)
      this.subscribeEvents(eventSource)
    },
    subscribeEvents(eventSource) {
      eventSource.addEventListener('UPDATE', async e => {
        this.showToast(e.data.split('<')[0])
      }, {once: false})

      eventSource.addEventListener('ASSIGN', async e => {
        this.showToast(e.data.split('<')[0])
      }, {once: false})

      eventSource.addEventListener('COMMENT', async e => {
        this.showToast(e.data.split('<')[0])
      }, {once: false})
    },

    updateNotificationCount() {
      axios.get('/notification/unread-count').then(value => {
        if (value.status === 200 && value.data){
          this.$store.commit('setNotificationCount', value.data);
          this.notification()
        }
      })

    },
    showToast(text){
      this.audio.play();
      this.$store.commit('incrementNotificationCount')
      toast.info(`${text}`, {
        autoClose: 9000
      });
    }
  },

  beforeUnmount() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
}
</script>

<style scoped>

</style>
