<template>
    <div class="row _center">
    <div :class="showSpace ? 'col s3' :''">
      <space-component :show-side-bar="!showSpace"/>
    </div>
    <div :class="showSpace ? 'col s9 _center' : 'col s12 _center' ">
      <router-view />
    </div>
  </div>

</template>

<script>
import SpaceComponent from "@/components/SpaceComponent";
import axios from 'axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


axios.defaults.headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  responseType: "json",
};

axios.interceptors.response.use(function (response) {
  if(response.config.method === 'put' ||
      response.config.method === 'post' ||
      response.config.method === 'patch' ||
      response.config.method === 'delete'  &&
      response.status === 200 ||
      response.status === 201){
    toast.success('Success', {
      autoClose: 500
    })
  }
  return response;
}, function (error) {
  if(error.response.status === 401 &&
      location.href.split('/').at(-1) !== 'auth' &&
      location.href.split('/').at(-1) !== 'register') {
    location.href = '/auth'
  }
  if(error.response.status === 422){
        toast.error(`${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`, {
          autoClose: 3000
        });
  }
  if(error.response.status === 403){
    toast.error(`Not right`, {
      autoClose: 3000
    });
  }
  if(error.response.status === 404){
    toast.error(`Not found`, {
      autoClose: 3000
    });
  }
  if(error.response.status === 500){
    toast.error(`Internal error`, {
      autoClose: 3000
    });
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  const tokens = localStorage.getItem('token');

  if (!tokens) {
    return config;
  }

  let token = tokens;

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default {
  name: 'App',
  // eslint-disable-next-line
  components: {SpaceComponent},

  data(){
    return {
      mobile: false,
      showSpace: true
    }
  },

  beforeMount() {
  },
  mounted() {
    localStorage.getItem('token')
    if(this.$store.state.token === '' && this.$router.currentRoute.value.path === '/register'){
      this.$router.push('/auth')
    }
    if(this.$router.currentRoute.value.path === '/auth' ||
        this.$router.currentRoute.value.path === '/register')
      this.showSpace = false


    if(this.$isMobile()) {
      this.mobile = true;
      this.showSpace = false;
      // if(this.$router.currentRoute.value.path === '/' &&
      //     !this.$router.currentRoute.value.name){
      //   this.$router.push('/spaces')
      // }
    }

    if(window.innerWidth < 1280){
      this.mobile = true;
      this.showSpace = false;
    }
  },

  watch: {
     '$route' (to) {
       if(this.$isMobile() || window.innerWidth < 1280)
        this.showSpace = false;
       else
        this.showSpace = !(to.path === '/auth' || to.path === '/register')
     }
  }
}
</script>

<style>
.row._center{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  max-width: 1700px;
  margin: auto;
}
.modal.open{
  max-width: 1440px !important;
}
.row._center .col.s9{
  margin-left: 0;
}
.col.s12._center{

  margin: auto;
}
.menu_item {
  display: flex;
}
.menu_item span {
  margin-left: 10px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
img{

  object-fit: cover;
}
body, textarea {
  overflow: auto !important;
  min-height: 3.5rem !important;
  max-height: 4rem !important;
}
body::-webkit-scrollbar, *::-webkit-scrollbar, *::-webkit-scrollbar  {
  width: .3em;
  height: .3em;
  cursor: default !important;
}

*::-webkit-scrollbar-track {
  cursor: default !important;
}

body::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
  background-color: #26a69a;
  cursor: default !important;
}
.col.s9._center{
  margin: 0 5%;
}

</style>

<style lang="scss">
.dropdown-content{
  max-height: 350px;
}
.col.s12{
  max-width: 1210px;
}
@media only screen and (max-width: 475px) {
  .modal_left, .modal_right{
    margin: 0 !important;
  }
  .drag-target{
    width: 60px !important;
    height: 85% !important;
    margin-top: 15% !important;
  }
  .modal.open {
    min-width: unset !important;
  }
  .testcases_list-left{
    width: 95vw !important;
    //height: 50vh;
    overflow-y: auto;
  }
  .testcases_list{
    flex-direction: column;
  }
  .testcases_list-right{
    width: 95vw !important;
  }
  #bugs{
    .filter{
      flex-direction: column;
      //width: 95vw  !important;
    }
    .filter_select{
      flex-direction: column;
    }
    .modal-header{
      flex-direction: column !important;
    }
    .modal-content{
      flex-direction: column !important;
    }
  }
  .collection .collection-item.avatar{
    min-height: 120px !important;
    align-items: flex-start  !important;
    margin-top: 20px !important;
  }
  .short_name_row{
    display: flex;
  }
  .left-content{
    flex-direction: column;
    align-items: flex-start !important;
  }
  .left-content__avatar{
    img{
      margin-left: 0 !important;
    }
    .circle.no-avatar{
      margin-left: 0 !important;
    }
    flex-direction: row-reverse !important;
    .short_name_row{
      flex-direction: row !important;
      max-width: 180px;
      width: unset !important;

      span{
        margin-right: 15px !important;

      }
    }
  }
  .secondary-content.in_row img{
    display: none !important;
  }
  .short_name, .short_name-external_task{
    max-width: 15vw !important;
  }
  .modal-footer{
    flex-direction: column !important;
  }
  .modal-footer_right{

    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-footer_left .tag{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .modal-footer {
    a {
      width: 130px !important;
      margin: 0 !important;
    }
    label{
      width: 100%;
    }
  }
  .modal-footer .btn{
    margin: 0 !important;
  }
  .main-report{
    flex-direction: column !important;
  }
  .collection-item.collection-item_suite{
    flex-direction: column !important;
  }
  .runs_statistic, .bugs_statistic{

    flex-direction: column !important;
    align-items: stretch !important;

  }
  .secondary-content{
    flex-wrap: wrap !important;
    position: unset !important;
  }
  .tab_bug__modal-mobile{
    display: flex !important;
  }
  .modal_right.__mobile, .modal_left.__mobile{
    display: none;
  }

  .modal_right__comments{
    min-height: 400px;
  }
}
</style>