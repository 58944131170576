<template>
  <div class="select_pad card-panel">

    <label class="select_pad--label">
      <span v-if="padFilterId">Pad</span>
      <select  class="" ref="selectPadForRunShow" @change="selectPadFilter()">
        <option value=""  selected >Pad</option>
        <option v-for="item in padFilter" :key="item.id" :value="item.id">{{item.name}}</option>
      </select>
    </label>

    <label class="select_pad--count">
      <select  class="" ref="selectCountForRun" @change="selectCountForRun($event)">
        <option value="1"   >1</option>
        <option value="5"   selected>5</option>
        <option value="10"   >10</option>
        <option value="20"   >20</option>
        <option value="50"   >50</option>
        <option value="100"  >100</option>
      </select>
    </label>


      <a class="show_all--runs" @click="selectAllRuns()">Show All </a>

  </div>

  <div v-for="pad in space.pad" :key="pad.id" >
    <div class="" v-if="pad.run.length > 0">
      <h5>{{pad.padName}}</h5>
      <div class="collection card" >
        <div class="collection-item" v-for="run in pad.run" :key="run.id" >
          <div class="progress" ref="progress_bar">
            <svg>
              <line v-if="run.itemsCount.all"
                  x1="0"
                  y1="0"
                  :x2="(progress_width * (run.itemsCount.pass / run.itemsCount.all))"
                  y2="0" class="progress_pass"  :stroke-dasharray="progress_width"/>
              <line v-if="run.itemsCount.all"
                  :x1="(progress_width * (run.itemsCount.pass / run.itemsCount.all))"
                  y1="0"
                  :x2="(progress_width * (run.itemsCount.pass / run.itemsCount.all)) + (progress_width * (run.itemsCount.fail / run.itemsCount.all))"
                  y2="0" class="progress_fail"   :stroke-dasharray="progress_width"  />
            </svg>
          </div>

          <a :href="`/runs/${run.id}`" class="run_name"> {{run.name}} </a>
          <div class="secondary-content">
              {{run.itemsCount.pass}}/{{run.itemsCount.all}} passed
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import M from "materialize-css";

export default {
  name: "PadRunsComponent",
  data(){
    return{
      spaceId: '',
      space: {
        spaceId: '',
        spaceName: '',
        pad: [{
          padId: '',
          padName: '',
          run: []
        }]
      },
      selectAll: false,
      progress_width: 0,
      padFilter: undefined,
      padFilterId: undefined,
      padName: undefined,
      runLimit: 5,
      filterParam: {
        runName: undefined,
        runCount: undefined
      }
    }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  async updated(){
    try {
      this.progress_width = this.$refs.progress_bar[0].clientWidth
      // eslint-disable-next-line
    }catch (e) {}
    this.filterParam.runName = M.FormSelect.init(this.$refs.selectPadForRunShow, {isMultiple: false});
    //
    this.filterParam.runCount = M.FormSelect.init(this.$refs.selectCountForRun, {isMultiple: false});

  },
  async mounted() {
    this.getFilter();

    // await this.getAllRuns();
    try {
      this.progress_width = this.$refs.progress_bar[0].clientWidth
      // eslint-disable-next-line
    }catch (e) {}
  },


  methods: {
    async getAllRuns(){
      this.space = (await axios.get(`/space/${this.spaceId}/all_runs`)).data
    },
    getFilter(){
      axios.get(`/runs/filter/${this.spaceId}`).then(value => {
        if(value.status === 200){
          this.padFilter = value.data
        }
      })
    },
    selectPadFilter(){
      this.space = {
        spaceId: '',
        spaceName: '',
        pad: [{
          padId: '',
          padName: '',
          run: []
        }]
      }
      const temp = this.$refs.selectPadForRunShow.value
      if(!temp){

        this.space.pad[0].run = []
        return
      }
      axios.get(`/runs/by-filter/${this.spaceId}?pad_id=${temp}&skip=0&limit=${this.runLimit}`).then(value => {
        if(value.status === 200){
          this.space.pad[0].run = value.data
        }
      })
    },
    selectCountForRun(e){
      if(e.target.value){
        this.runLimit = e.target.value;
        this.selectPadFilter()
      }
    },
    async selectAllRuns(){
      if(!this.selectAll) {
        await this.getAllRuns()
      }
      this.selectAll = !this.selectAll
    }
  }
}
</script>

<style scoped>
.progress{
  /*width: 100px;*/
  height: 4px;
  /*box-shadow: 0 0 10px 0px;*/
  background-color: #e0f2f1 ;
}
.progress svg{
  width: 100%;
}
.progress_pass{
  stroke-width: 15;
  stroke: #1de9b6  ;
}
.progress_fail{
  stroke-width: 15;
  stroke: #ef5350 ;
}
a.run_name{
  color: #26a69a;
}
.select_pad{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.select_pad .select_pad--label{
  width: 75%;
}
.select_pad .select_pad--count{
  width: 10%;
}
.show_all--runs{
  color: #26a69a;
  cursor: pointer;
}
</style>