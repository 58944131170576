<template>
<!--  <div class="sidenav_button sidenav-trigger"  data-target="slide-out">-->
  <div class="sidenav_button sidenav-trigger"  data-target="slide-out" v-if="showSideBar" @click="sidenavControl">
    <i class="material-icons">menu</i>
  </div>
  <ul id="slide-out" :class="!showSideBar?'sidenav active':'sidenav '" ref="slide">
    <li>
      <div class="user-view">

        <router-link :to="'/settings/profile'"> <img class="circle" :src="`${this.user.avatar?.filepath ?? ''}`"> </router-link>


        <notification-ring-component :user="user" v-if="user.id"/>
        <router-link :to="'/news'" :class="'news-link'"><i class="material-icons">pages</i> </router-link>


        <a><span class="black-text name">{{this.user.username ? this.user.username : 'username'}}</span></a>
        <a><span class="black-text email">{{this.user.mail}}</span></a>
      </div>

    </li>
    <li><div class="divider"></div></li>
    <li><a class="subheader">Spaces</a></li>
    <li
        v-for="space in spaces" :key="space.id"
        :to="space.edit? `` : `/space/${space.id}`"
        :ref="`space_id__${space.id}`"
        @contextmenu="onContextMenu($event)"
        class="collection-item"
        :class="spaceId === space.id ? 'active' :''"
        @click="clickSpace($event, space.id)"
    >
        <a class="" :href="`/space/${space.id}`">
          {{space.name}}
        </a>
    </li>
    <li v-if="spaces.length === 0"
        @contextmenu="onContextMenu($event)"
        class="collection-item">
      <a class="waves-effect" >
        Please create space
      </a>
    </li>
  </ul>



  <context-menu
      v-model:show="show"
      :options="optionsComponent"
  >
    <context-menu-item>
      <div class="menu_item" @click="createSpace">
        <i class="material-icons">add</i> <span >New Space</span>
      </div>
    </context-menu-item>
    <context-menu-item>
      <div class="menu_item" @click="getSettings">
        <i class="material-icons">settings</i> <span>Settings</span>
      </div>
    </context-menu-item>
    <context-menu-item>
      <div class="menu_item" @click="getStatisticSpace">
        <i class="material-icons">insert_chart</i> <span>Statistic</span>
      </div>
    </context-menu-item>
    <context-menu-item>
      <div class="menu_item" @click="getReportSpace">
        <i class="material-icons">pie_chart</i> <span>Report</span>
      </div>
    </context-menu-item>
    <context-menu-item>
      <div class="menu_item" @click="getQaReportSpace">
        <i class="material-icons">bug_report</i> <span>Report</span>
      </div>
    </context-menu-item>
  </context-menu>


</template>

<script>
import axios from "axios";
import NotificationRingComponent from "@/components/notification/NotificationRingComponent";
import M from 'materialize-css'

export default {
  name: "SpaceComponent",
  components: {NotificationRingComponent},
  props: {
    showSideBar: String,
  },
  data(){
    return{
      user: {
        id: '',
        name: '',
        mail: '',
        avatar_id: ''
      },
      userAvatar: '',
      spaces: [{
        id: '',
        name: '',
        sort: 0,
        edit: false,
        avatar: {
          filepath: ''
        }
      }],
      show: false,
      optionsComponent: {
        iconFontClass: 'iconfont',
        customClass: "class-a",
        zIndex: 9999,
        minWidth: 230,
        x: 500,
        y: 200
      },
      activeId: '',
      spaceId: '',
      activeMenuSpaceId: '',
      sidenav: undefined
    }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;

      },
    },
  },
  async mounted() {
    this.sidenav = M.Sidenav.init(this.$refs.slide)

    // this.spaces =  (await axios.get('/space')).data;
    axios.get('/space').then(value => this.spaces = value.data)
    // this.user = (await axios.get('/user')).data
    axios.get('/user').then(value => this.user = value.data)
    // if(this.user.avatar_id){
    //   this.userAvatar = process.env.BASE_URL  + (await axios.get(`/file/${this.user.avatar_id}`)).data.filepath
    // }

    if (this.spaceId) {
      // space_id__

      localStorage.setItem('spaceId', this.$route.params.spaceId);
      this.activeMenuSpaceId = this.$route.params.spaceId;
      this.spaceId = this.$route.params.spaceId;
    }
    // this.$refs[`space_id__${this.spaceId}`].scrollIntoView({ behavior: 'smooth' })

  },
  async serverPrefetch(){
    console.log(this.spaceId)
  },
  methods: {
    sidenavControl(){
      this.sidenav.close()
    },
    async createSpace(){
      const temp = (await axios.post('/space', {
        name: 'new space'
      } )).data;
      this.spaces.push(temp);
      this.activeId = '';
    },
    async clickSpace(e, spaceId){
      this.spaceId = spaceId;

      if(this.$route.params.spaceId) localStorage.setItem('spaceId', spaceId);

      if(e.target.tagName !== 'INPUT' && this.activeId !== '') {
        const index = this.spaces.findIndex(value => value.id === this.activeId);
        this.spaces[index].edit = false;
        this.activeId = '';
      }
    },
    async saveSpace(e){
      const index = this.spaces.findIndex(value => value.id === this.activeId);

      await axios.put(`/space/${this.activeId}`, {
        name: e.target.value
      });

      this.spaces[index].name = e.target.value;
      this.spaces[index].edit = false;
      this.activeId = '';
    },
    async deleteSpace(){
      const index = this.spaces.findIndex(value => value.id === this.activeId);
      await axios.delete(`/space/${this.activeId}`, );
      this.spaces.splice(index, 1);
      this.activeId = '';
    },
    async getSettings(){
      // const index = this.spaces.findIndex(value => value.id === this.activeId);
      // this.$router.push(`/settings/space/${this.activeId}`)
      this.$router.push(`/settings/space/${this.activeId}`)
    },
    async getReportSpace(){
      this.$router.push(`/report/space/${this.activeId}`)
    },
    async getStatisticSpace(){
      this.$router.push(`/statistics/space/${this.activeId}`);
    },
    async getQaReportSpace(){
      this.$router.push(`/space/${this.activeId}/report-qa`)
    },
    async renameSpace(){
      const index = this.spaces.findIndex(value => value.id === this.activeId);
      this.spaces[index].edit = true;
    },

    async onContextMenu(e) {
      let index = -1;
      e.preventDefault();
      if(this.activeId !== '') {
        index = this.spaces.findIndex(value => value.id === this.activeId);
        this.spaces[index].edit = false;
        try {
          this.activeId = e.target.href.split('/').at(-1);
        } catch {
          this.activeId = ''
        }
      }else {
        try {
          this.activeId = e.target.href.split('/').at(-1);
        } catch {
          this.activeId = ''
        }

      }
      //Set the mouse position
      this.optionsComponent.x = e.x;
      this.optionsComponent.y = e.y;
      //Show menu
      this.show = true;
    },
  },
}
</script>

<style scoped>
.sidenav.active{
  transform: translateX(0%)
}
.btn_absolute{
  position: absolute;
  top: 4px;
  left: 4px;
}
.notification-icon{
  position: fixed;
  top: 0;
  right: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  color: #26a69a;
}
.news-link{
  color: #26a69a;
  position: fixed;
  top: 48px;
  right: 15px;
}
.sidenav_button{
  cursor: pointer;
  color: white;
  left: 0;
  bottom: 0;
  border-radius: 50px;
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  width: 56px;
  height: 56px;
  background-color: #26a69a !important

}
</style>

