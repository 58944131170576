import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
import VueMobileDetection from "vue-mobile-detection";
import { createStore } from 'vuex'
import VueSSE from 'vue-sse';

import 'vue-toastification/dist/index.css';
const app = createApp(App)

const store = createStore({
    state() {
        return{
            token: localStorage.getItem('token') || '',
            spaceId: localStorage.getItem('spaceId') || '',
            notificationCount: 0
        }
    },
    getters: {
        notificationCount: state => {
            return state.notificationCount;
        },
    },
    mutations: {
        setNotificationCount: (state, count) => {
            state.notificationCount = count;
        },
        decrementNotificationCount: (state ) => {
            state.notificationCount--;
        },
        incrementNotificationCount: (state ) => {
            state.notificationCount++;
        },
    }
})



app
    .use(store)
    .use(VueMobileDetection)
    .use(ContextMenu)
    .use(router)
    .use(VueSSE)
    .mount('#app')
