<template>

  <label><input type="text" v-model="newFolderModel" placeholder="New folder" @keydown.enter="addFolder"></label>

  <div class="divider"></div>


  <div class="folder" :class="elem.open ? 'open' :'' " v-for="elem in folder" :key="elem.id">
    <div class="folder_name"  >
      <div class="folder_name-left" @click="openFolder($event, elem.id)">
        <i class="material-icons rotate">arrow_drop_down</i>
        <h6>{{ elem.name }}</h6>
      </div>

      <div class="folder_name-right">
        <i class="material-icons edit" @click="editFolder(elem.id)">edit</i>
      </div>
    </div>

    <pad-list-collection-component :folder-id="elem.id" :space-id="spaceId" v-if="elem.open" :folders="folder" v-on:update-folder="updateFolder($event)"/>

  </div>

  <div class="divider"></div>

  <pad-list-collection-component :space-id="spaceId" :folders="folder"/>

  <div class="modal" ref="modalFolder">
    <div class="modal-content">
      <label><input type="text" v-model="folderNameModel"></label>
    </div>

    <div class="modal-footer">
      <div class="modal-footer_left">
        <a  class="btn waves-light red" @click="deleteFolder">Delete
          <i class="material-icons right">delete</i>
        </a>
      </div>
      <div class="modal-footer_right">
        <a  class="btn  waves-light" @click="closeModal">cancel
          <i class="material-icons right">cancel</i>
        </a>
        <a  class="btn waves-light" @click="updateFolderName">save
          <i class="material-icons right">save</i>
        </a>

      </div>
    </div>
  </div>

  <div class="modal-overlay" ref="modalFolder_overlay" @click="closeModal"></div>
</template>

<script>
import axios from "axios";
import PadListCollectionComponent from "@/components/pads/pads-list/PadListCollectionComponent";

export default {
  name: "PadListComponent",
  emits: ["update-folder"],
  components: {PadListCollectionComponent},
  data(){
    return{
      newFolderModel: undefined,
      spaceId: '',
      pads: [],
      folder: [],
      activeId: '',
      padsModal: [],
      folderModal: undefined,
      folderNameModel: undefined
    }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;

      },
    },
  },
  async mounted(){
    this.getPads();
  },
  methods: {
    async updateFolder(){
      // this.folder = (await axios.get(`/pad-folder/${this.spaceId}`)).data;
    },
    goToThing(e){
      e.stop();
      e.preventDefault();

      return false;
    },
    getPads(){
      this.folder = [];
      // this.folder = (await axios.get(`/pad-folder/${this.spaceId}`)).data;
      axios.get(`/pad-folder/${this.spaceId}`).then(value => this.folder = value.data)
      // this.pads = (await axios.get(`/pad/${this.spaceId}`)).data;
    },


    async openFolder(e, folderId){
      this.folder.find(value => value.id === folderId).open = !this.folder.find(value => value.id === folderId).open;
      e.target.parentElement.parentElement.parentElement.classList.toggle('open');
    },
    toggleModal(){
      this.$refs.modalFolder_overlay.classList.toggle('open');
      this.$refs.modalFolder.classList.toggle('open');
    },
    async editFolder(folderId){
      this.folderModal = folderId;
      this.folderNameModel = this.folder.find(value => value.id === folderId).name;
      this.toggleModal();
    },
    async closeModal(){
      this.folderModal = undefined;
      this.toggleModal();
    },
    async updateFolderName(){
      const temp = await axios.patch(`/pad-folder/${this.folderModal}`, {name: this.folderNameModel})
      if(temp.status === 200) {
        this.folder.find(value => value.id === this.folderModal).name = this.folderNameModel;
        this.folderNameModel = undefined;
        await this.closeModal();
      }
    },
    async addFolder(){
      const temp = await axios.post(`/pad-folder/${this.spaceId}`, {name: this.newFolderModel});
      if(temp.status === 200){
        this.folder.push(temp.data);
        this.newFolderModel = undefined
      }
    },

    async deleteFolder(){
      const temp = await axios.delete(`/pad-folder/${this.folderModal}`)
      if(temp.status === 200){
        const index = this.folder.findIndex(value => value.id === this.folderModal)
        this.folder.splice(index, 1);
        await this.closeModal();
      }
    }
  },
}
</script>

<style scoped>
.folder_name{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.folder_name-left{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.folder_name i{
  cursor: pointer;
}
.folder_name h6{
  width: 100%;
}
.folder.open i.rotate{
  transform: rotate(180deg);
}
.folder .collection{
  display: none;
}
.folder i.edit{
  display: none;
}
.folder.open i.edit{
 display: inline-block;
}
.folder.open .collection{
  display: block;
}
.modal.open{
  z-index: 4001;
  display: block;
  opacity: 1;
  transform: scaleX(1) scaleY(1);
  min-width: 600px;
  max-height: 80vh;
  width: 256px;
  top: calc(70vh - 256px);
}


.modal-overlay.open{
  z-index: 1002;
  display: block;
  opacity: 0.5;
}
.modal-footer{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.modal-footer a{
  margin: 0 25px !important;
}
.testcase_link a{
  cursor: pointer;
  color: #26a69a;
}
</style>