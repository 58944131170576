<template>
  <div class="row">
    <h1>test</h1>
    <div v-html="''"></div>
  </div>
</template>

<script>
export default {
  name: "NewsDetailComponent",
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>