<template>
  <div class="row">
    <div class="col s12">
      <ul id="tabs-swipe-demo" class="tabs tabs-fixed-width" @click="test($event)" ref="tabsList">
        <li class="tab "><a  href="#bugs" tabindex="bugs">Bugs</a></li>
        <li class="tab "><a  href="#pads" tabindex="pads">Pads</a></li>
        <li class="tab "><a  href="#runs" tabindex="runs">Runs</a></li>
        <li class="tab "><a  href="#testcases" tabindex="testcases">Test cases</a></li>
        <li class="tab "><a  href="#notes" tabindex="notes">Notes</a></li>
      </ul>
    </div>
    <transition name="slide-fade">
    <div class="tab_body">
      <div id="bugs" class="col s12" v-if="this.tabActive==='bugs'"><PadBugsComponent /></div>
      <div id="pads" class="col s12" v-if="this.tabActive==='pads'"><PadListComponent /></div>
      <div id="runs" class="col s12" v-if="this.tabActive==='runs'"><PadRunsComponent /></div>
      <div id="testcases" class="col s12" v-if="this.tabActive==='testcases'"><TestcasesListComponent /></div>
      <div id="notes" class="col s12" v-if="this.tabActive==='notes'"><PadNotesComponent /></div>
    </div>
    </transition>
  </div>



</template>

<script>


import PadListComponent from "@/components/pads/pads-list/PadListComponent";
import PadRunsComponent from "@/components/pads/run/PadRunsComponent";
import PadBugsComponent from "@/components/pads/bugs/PadBugsListComponent";
import PadNotesComponent from "@/components/pads/note/PadNotesComponent";
import M from "materialize-css";
import TestcasesListComponent from "@/components/pads/testcases/TestcasesListComponent";

export default {

  name: "PadMainComponent",
  components: {TestcasesListComponent, PadNotesComponent, PadBugsComponent, PadRunsComponent, PadListComponent},
  data(){
      return{
        tabActive: 'bugs',
        space_id: '',
        bugShortName: undefined,
        navPad: undefined
      }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  mounted() {
    this.navPad= M.Tabs.init(this.$refs.tabsList, {},{swipeable: true});
    const temp = this.$route.hash.split('?');

    console.log(temp)

    if(temp[0]){
      this.tabActive = temp[0].replace('#', '')
      this.navPad.select(this.tabActive)
      if (temp[1])
        this.$router.push(`${temp[0]}?${temp[1]}`)
    }else{
      this.$router.push('#bugs')
    }
    if(this.$route.query.shortName){
      this.tabActive = 'bugs'
      this.navPad.select('bugs')
      this.spaceId = this.$route.params.spaceId;
      localStorage.setItem('spaceId', this.spaceId);
      this.bugShortName = this.$route.query;
    }

  },
  async updated() {
  },
  methods: {
    async testSSE(){

    },
    test(e){
      e.preventDefault();
      this.$router.push(e.target.getAttribute('href'))

      this.tabActive = e.target.getAttribute('tabindex');
    }
  }

}
</script>

<style scoped>
.tabs .tab a{
  color: #26a69a;
}
.tabs .tab a:hover, .tabs .tab a.active{
  background-color: #e0f2f1  ;
  cursor: pointer;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
