<template>
  <h4>Reports</h4>
  <div class="link_cov"><router-link v-if="coverageLink" :to="coverageLink">coverage</router-link></div>
  <div class="card-panel">
    <div class=" filter">
      <label>
        <span>Report</span>
        <select class="" ref="reportPickerFilter" @change="selectTest($event)">
          <option value="" selected>Report</option>
          <option
              v-for="report in reportList"
              :key="report.id"
              :value="report.id">
            {{report.name }} {{this.lux.fromISO(report?.create_at).toFormat('MMMM dd, yyyy HH:mm')}}
          </option>
        </select>
      </label>
    </div>
  </div>

  <div class="main-report">
    <div class="card-panel main-status_list" v-if="testSuite?.suites?.length > 0">
      <div class="list_status">
        <p>PASSED: <span class="color_passed">{{testSuite?.status_list?.PASSED}}</span></p>
        <p>FAILED: <span class="color_failed">{{testSuite?.status_list?.FAILED}}</span></p>
        <p>SKIPPED: <span class="color_skipped">{{testSuite?.status_list?.SKIPPED}}</span></p>
        <p>UNKNOWN: <span class="color_unknown">{{testSuite?.status_list?.UNKNOWN}}</span></p>
      </div>

      <div class="time_test">
        {{testSuite?.duration}}
      </div>
    </div>

    <div class="card-panel" v-if="testSuite?.suites.length > 0">
      <report-trend-chart  :data-chart="trendData"/>
    </div>
    <div class="card-panel" v-if="testSuite?.suites.length > 0">
      <report-pie-chart  :data-chart="testSuite.status_list"/>
    </div>
  </div>

  <report-suite-component v-if="testSuite?.suites.length > 0" :suite="testSuite?.suites"/>


</template>

<script>
import M from 'materialize-css'
import ReportSuiteComponent from "@/components/report-portal/ReportSuiteComponent";
import axios from "axios";
import { DateTime } from "luxon";
import ReportTrendChart from "@/components/report-portal/chart/ReportTrendChart";
import ReportPieChart from "@/components/report-portal/chart/ReportPieChart";

export default {
name: "ReportComponent",
  components: {ReportTrendChart, ReportSuiteComponent, ReportPieChart},
  data(){
    return{
      lux: DateTime,
      spaceId: undefined,
      reportList: undefined,
      testSuite: undefined,
      trendData: undefined,
      coverageLink: undefined
    }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  async mounted() {
    if (!this.spaceId) return;
    let temp = await axios.get(`/report/test/list/${this.spaceId}`);
    if (temp.status !== 200) return;
    this.coverageLink = `/report/space/${this.spaceId}/coverage`;
    this.reportList = temp.data;


  },
  updated() {
    M.FormSelect.init(this.$refs.reportPickerFilter, {isMultiple: false});
  },
  methods: {
    async selectTest(e){
      if(!e.target.value) this.testSuite = {suites: []};
      await this.getTestSuite(e.target.value);
    },
    async getTestSuite(suiteId){
      this.testSuite = {suites: []};
      this.testSuite = (await axios.get(`/report/test/detail/${suiteId}`)).data;
      const suites = this.testSuite?.suites;
      const temp = {
        passed: [0],
        failed: [0],
        label: []
      }
      let i = 0;
      if (suites.length === 0) return;
      for (const suite of suites){
        temp.label.push(`#${i++}`);
        temp.passed.push(suite.status_list.PASSED + temp.passed[temp.passed.length-1]);
        temp.failed.push(suite.status_list.FAILED + temp.failed[temp.failed.length-1]);
      }
      temp.passed.shift();
      temp.failed.shift();
      this.trendData = temp;
    }
  }
}
</script>


<style scoped>
.color_failed{
  color: #fc6766;
  font-weight: 800;
}
.color_passed{
  color: #98cc64;
  font-weight: 800;
}
.color_skipped{
  color: #ffd050;
  font-weight: 800;
}
.color_unknown{
  color: #d35fbf;
  font-weight: 800;
}
.main-report{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.main-status_list{
  width: 348px;
  height: 192px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>