<template>
  <div class="run-sub" v-for='elem in item' :key='elem.id' >
    <div class="run-sub_item collection-item" :class="elem.subItem.length > 0  ? 'main' :''">
      <div>{{elem.text}}</div>
      <div>
        <select class="browser-default custom_select" @change="onChange($event)" :id="elem.id" v-model="elem.state">
          <option value="" v-if="!elem.state"></option>
          <option value="pass" :selected="elem.state==='pass'">pass</option>
          <option value="fail" :selected="elem.state==='fail'">fail</option>
        </select>
      </div>
      <div class="secondary-content pass" v-if="elem.state==='pass'"></div>
      <div class="secondary-content fail" v-else-if="elem.state === 'fail'"></div>
    </div>
    <div v-if="elem.runItemsTestcases.length > 0">

        <div class="cases collection-item" v-for="cases in elem.runItemsTestcases" :key="cases.testcase.id" :id="cases.testcase.id" @click="openCase(cases.testcase.id)">
          <div >{{cases.testcase.title}}</div>
        </div>

    </div>

    <run-sub-item-component :items="elem.subItem" v-if="elem.subItem" v-on:update-count="getItems" v-on:open-case="openCase"/>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "RunSubItemComponent",
  emits: ['update-count', 'open-case'],
  props:{
    items: {
      type: Array
    }
  },
  data(){
    return{
      item: []
    }
  },
  mounted() {
    this.item = this.items
  },
  methods:{
    async openCase(caseId){
      this.$emit('open-case', caseId);
    },
    async onChange(e){
      await axios.patch(`/runitems/${e.target.id}?state=${e.target.value}`)
      this.$emit('update-count')
    },
    getItems(){
      this.$emit('update-count')
    },

  }
}
</script>

<style scoped>
.collection .collection-item{
  padding: 5px 20px;
}
.collection-item.cases{
  color: #26a69a;
  cursor: pointer;
}
.run-sub_item div:nth-child(2){
  margin-right: 50px;
  /*width: 60vw;*/
}
.run-sub_item {
  display: flex;
  background-color: #fbfbfa;

  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.run-sub {
  margin-left: 20px;
  border-left: 1px dashed#787878;
}
.run-sub_item.main{
  background-color: #f7f7f7;
}
.custom_select {
  background-color: rgba(255,255,255,0.9);
  width: 100%;
  padding: 0px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 1.5rem;
}
.secondary-content{
  margin-left: 5vw;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  right: 20px;
}
.secondary-content.fail{
  background-color: #ef5350;
}
.secondary-content.pass{
  background-color: #1de9b6;
}

</style>