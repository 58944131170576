<template>
  <a href="#" data-target="slide-out_left" class="sidenav-trigger bug show-on-large"><i class="material-icons">menu</i></a>

  <div id="slide-out_left" class="sidenav left" ref="sidenav_left">
    <pad-bugs-component ></pad-bugs-component>
  </div>


  <div class="run_main">
    <div class="card-panel progress_main_block" ref="progress_main_block">
      <div class="progress_count">
        <span>{{this.passed_count}}/{{this.all}} passed </span>
      </div>

      <div class="progress" ref="progress_bar">
        <svg>
          <line x1="0" y1="0" :x2="passed" y2="0" class="progress_pass"  :stroke-dasharray="progress_width"/>
          <line  :x1="passed" y1="0" :x2="passed+failed" y2="0" class="progress_fail"   :stroke-dasharray="progress_width"  />
        </svg>
      </div>
    </div>

    <div>
      <label>
          <textarea
              type='text'
              class="materialize-textarea"
              v-model='run.name'
              @focusout="saveRun($event)"
              @keydown.enter="saveRun($event)"
          />
      </label>
    </div>
    <div class="run collection card" v-if="items.length > 0">

      <div v-for="item in items" :key="item.id">
        <div class="run-main_item collection-item" :id="item.id">
          <div>{{item.text}}</div>
          <div>
            <select class="browser-default custom_select" @change="onChange($event)" :id="item.id" >
              <option value="" v-if="!item.state"></option>
              <option value="pass" :selected="item.state==='pass'">pass</option>
              <option value="fail" :selected="item.state==='fail'">fail</option>
            </select>
          </div>

          <div class="secondary-content pass" v-if="item.state==='pass'"></div>
          <div class="secondary-content fail" v-else-if="item.state === 'fail'"></div>

        </div>

        <div v-if="item.runItemsTestcases.length > 0">


            <div class="cases collection-item" v-for="cases in item.runItemsTestcases" :key="cases.testcase.id" :id="cases.testcase.id" @click="openCase(cases.testcase.id)">
              <div>{{cases.testcase.title}}</div>
            </div>

        </div>

        <run-sub-item-component :items="item.subItem" v-if="item.subItem" v-on:update-count="getItems" v-on:open-case="openCase"/>
      </div>
    </div>
  </div>

  <div ref="modal_testcase" class="modal ">
    <div class="modal-header">
      <h2>{{modal.header}}</h2>
    </div>
    <div class="modal-content" v-html="modal.content">

    </div>
  </div>
  <div class="modal-overlay" ref="modal__overlay" @click="close"></div>

</template>

<script>
import axios from 'axios'
import RunSubItemComponent from "@/components/pads/run/RunSubItemComponent";
import M from 'materialize-css'
import PadBugsComponent from "@/components/pads/bugs/PadBugsListComponent";

export default {
name: "RunComponent",
  components: {PadBugsComponent, RunSubItemComponent},
  emits: ['update-count'],
  data() {
    return {
      runId: this.$route.params.runId,
      newItem: '',
      items: [],
      run: {},
      passed: 0,
      failed_width: 0,
      passed_width: 0,
      failed: 0,
      all: 0,
      progress_width: 0,
      passed_count: 0,
      failed_count: 0,
      modal: {
        header: '',
        content: '',
      },
    }
  },
  async mounted() {
    window.addEventListener("scroll", this.onScroll, true)
    await this.getItems();
    this.run = (await axios.get(`/runs/${this.runId}`)).data;
    this.progress_width = this.$refs.progress_bar.clientWidth;
    M.Sidenav.init(this.$refs.sidenav_left,
        {
          onOpenStart: this.openSideLeft,
          onCloseStart: this.closeSideLeft
        }
        );
  },
  methods: {
    async openCase(caseId){
      this.$refs.modal_testcase.classList.toggle('open');
      this.$refs.modal__overlay.classList.toggle('open');
      const temp = (await axios.get(`/testcases/detail/${caseId}`)).data;

      let contentModal = {
        text: '',
        steps: '',
        expectedResults: ''
      }
      try {
        contentModal.text = (temp.text.match(/[\w]{2}/g) ? `<h5>Description</h5><div class="card-panel">${temp.text}</div>`: '')
        // eslint-disable-next-line
      }catch (e) {}
      try {
        contentModal.steps = (temp.steps.match(/[\w]{2}/g) ?`<h5>Steps</h5><div class="card-panel">${temp.steps}</div>`: '')
        // eslint-disable-next-line
      }catch (e) {}
      try {
        contentModal.expectedResults = (temp.expected_results.match(/[\w]{2}/g) ?`<h5>Expected results</h5><div class="card-panel">${temp.expected_results}</div>`: '')
        // eslint-disable-next-line
      }catch (e) {}

      let content = contentModal.text + contentModal.steps + contentModal.expectedResults

      this.modal = {
        header: temp.title,
        content: content,
      }
    },
    close(){
      this.$refs.modal_testcase.classList.toggle('open');
      this.$refs.modal__overlay.classList.toggle('open');
      this.modal = {
        header: '',
        content: '',
      }
    },
    openSideLeft(){
      this.$refs.sidenav_left.classList.toggle('active');
    },
    closeSideLeft(){
      this.$refs.sidenav_left.classList.toggle('active');
    },
    onScroll() {
      if(window.scrollY  === 0 ) return
      try {
        if(window.scrollY > 130){
          this.$refs.progress_main_block.classList.add('fixed')
        }
        else if(window.scrollY < 140){
          this.$refs.progress_main_block.classList.remove('fixed')
        }
        // eslint-disable-next-line
      }catch (e) {}
      
    },
    async getItems() {
      const temp = (await axios.get(`/runs/items/${this.runId}`)).data;
      this.items = temp.items;
      this.all = temp.allCount;
      this.failed_count = temp.failed;
      this.passed_count = temp.passed;
      this.updateRunsProgress()
    },
    async onChange(e){
      const res = await axios.patch(`/runitems/${e.target.id}?state=${e.target.value}`)
      if(res.status !== 200) return
      const temp = this.items.find(value => value.id === e.target.id)


      if(temp.state === 'pass' && e.target.value==='fail') {
        this.passed_count--;
        this.failed_count++;
      }
      if(temp.state === 'fail' && e.target.value==='pass') {
        this.passed_count++;
        this.failed_count--;
      }
      if(e.target.value==='pass' && !temp.state)
        this.passed_count++
      else if(e.target.value==='fail' && !temp.state)
        this.failed_count++

      temp.state = e.target.value
      this.updateRunsProgress()
    },
    async saveRun(e){
      await axios.patch(`/runs/${this.runId}?name=${this.run.name}`)
      e.preventDefault();
    },
    updateRunsProgress(){
      this.progress_width = this.$refs.progress_bar.clientWidth

      this.passed = this.progress_width * (this.passed_count  / this.all);
      this.failed = this.progress_width * (this.failed_count  / this.all);
    }
  }
}
</script>

<style scoped>
.collection .collection-item:last-child{
  border-bottom: 1px solid #e0e0e0;
}
.collection{
  border-bottom: none;
}
.collection-item.cases{
  cursor: pointer;
  color: #26a69a;
}
.run-main_item:nth-child(1){
  border-top: 1px solid #d0e0de;
}
.run-main_item div:nth-child(2){
  margin-right: 50px;
  /*width: 60vw;*/
}
.run-main_item {

}
.sidenav.left{
  width: 85vw;
  padding: 2rem;
  z-index: 1003;
}
.sidenav-trigger.bug{
  position: fixed;
  right: 0;
  top: 0;
  color: #26a69a;
}
.run-sub_item:nth-child(1) {
  background-color: #d0e0de;
}
.run-sub_item:nth-child(2n+1) {
  background-color: rgba(242,242,242,0.5);
}
.run-main_item {
  display: flex;
  background-color: #e3e3e3;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;


}
.custom_select {
  background-color: rgba(255,255,255,0.9);
  width: 100%;
   padding: 0px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 1.5rem;
}
.secondary-content{
  margin-left: 5vw;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  right: 20px;
}
.secondary-content.fail{
  background-color: #ef5350;
}
.secondary-content.pass{
  background-color: #1de9b6;
}

.progress{
  /*width: 100px;*/
  height: 7px;
  /*box-shadow: 0 0 10px 0px;*/
  background-color: #e0f2f1 ;
}
.progress svg{
  width: 100%;
}
.progress_pass{
  stroke-width: 15;
  stroke: #1de9b6  ;
}
.progress_fail{
  stroke-width: 15;
  stroke: #ef5350 ;
}
.progress_main{
  stroke-width: 16;
  stroke: rgb(255, 255, 255);
}
.progress_main_block.fixed{
  position: fixed;
  z-index: 901;
  width: 70vw;
  margin-top: 0;
}
.collection .collection-item{
  padding: 5px 20px;
}
</style>