<template>
  <h4>Suites</h4>
  <div class="card-panel" v-for="elem in item" :key="elem.id">
    <div class="collection">
      <div class="collection-item collection-item_suite">
        <div class="collection-item_suite-info">


          <div class="collection-item_suite-top">
            <h5>{{elem.name}}</h5>
            <div class="collection-item_suite-top subheader">
              <span>{{this.lux.fromISO(elem?.create_at).toFormat('MMMM dd, yyyy HH:mm')}}</span>
              <span>{{elem?.timeTaken ? `timeTaken: ${elem?.timeTaken}` : ''}} </span>
            </div>
          </div>

          <div class="collection-item_suite-bottom">
            <p>PASSED: <span class="color_passed">{{elem?.status_list?.PASSED}}</span></p>
            <p>FAILED: <span class="color_failed">{{elem?.status_list?.FAILED}}</span></p>
            <p>SKIPPED: <span class="color_skipped">{{elem?.status_list?.SKIPPED}}</span></p>
            <p>UNKNOWN: <span class="color_unknown">{{elem?.status_list?.UNKNOWN}}</span></p>
          </div>
        </div>
        <div class="suite-report_pie_chart">

          <report-pie-chart v-if="elem?.status_list" :data-chart="elem.status_list"/>
        </div>
      </div>

      <report-case-component v-if="elem.cases.length > 0" :cases="elem.cases"/>
    </div>

  </div>
</template>

<script>
import ReportPieChart from "@/components/report-portal/chart/ReportPieChart";
import ReportCaseComponent from "@/components/report-portal/ReportCaseComponent";
import {DateTime} from "luxon";

export default {
  name: "ReportSuiteComponent",
  components: {ReportCaseComponent, ReportPieChart},
  props:{
    suite: {
      type: Array
    }
  },
  data(){
    return{
      lux: DateTime,
      item: []
    }
  },
  mounted() {
    this.item = this.suite
  }
}
</script>

<style scoped>

.color_failed{
  color: #fc6766;
  font-weight: 800;
}
.color_passed{
  color: #98cc64;
  font-weight: 800;
}
.color_skipped{
  color: #ffd050;
  font-weight: 800;
}
.color_unknown{
  color: #d35fbf;
  font-weight: 800;
}
.collection-item_suite-top.subheader{
  color: #9e9e9e;
  font-size: 12px;
}
.collection-item_suite-info{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  height: 150px;
  align-content: flex-start;
  align-items: stretch;
}
.collection{
  border: initial;
  overflow: initial;
}
.collection-item_suite{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
}
.collection-item_suite-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.collection-item_suite-top p, .collection-item_suite-bottom p{
  margin: 0;
  margin-right: 10px;
}
</style>