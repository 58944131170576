<template>
  <div v-if="editor" class="editor_tiptap">
    <bubble-menu
        class="bubble-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
    >
      <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        Bold
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        Italic
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        Strike
      </button>
    </bubble-menu>

    <floating-menu
        class="floating-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
    >
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        H1
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        H2
      </button>
      <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        Bullet List
      </button>

      <button v-if="this.testcase" @click="openFileUpload">
        setImage
      </button>
    </floating-menu>
    <input type="file" ref="fileInput" style="display: none" multiple accept="image/jpeg, image/png" @change="uploadFile">
  </div>
  <editor-content :editor="editor" />


</template>

<script>
/* eslint-disable */
import {
  BubbleMenu,
  FloatingMenu,
  Editor,
  EditorContent } from '@tiptap/vue-3'

import axios from "axios";

export default {
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
    Image,
  },
name: "BubbleMenuComponent",
  props: {
    editor: {
      type: Editor,
      required: true,
    },
    testcase: false,
    testcaseId: String,
  },
  mounted() {
    this.editor.setOptions({
      editorProps: {
        handlePaste: (view, event) => {
          if (event.clipboardData.files.length) {
            const hasFiles =
                event.clipboardData &&
                event.clipboardData.files &&
                event.clipboardData.files.length

            if (!hasFiles) {
              return
            }

            const images = Array.from(
                event.clipboardData.files
            ).filter(file => /image/i.test(file.type))

            if (images.length === 0) {
              return
            }

            event.preventDefault()

            const { schema } = view.state

            images.forEach(image => {
              const reader = new FileReader()

              reader.onload = readerEvent => {
                const node = schema.nodes.image.create({
                  src: readerEvent.target.result
                })
                const transaction = view.state.tr.replaceSelectionWith(node)
                view.dispatch(transaction)
              }
              reader.readAsDataURL(image)
            })
            return true
          }
          return false
        },

        // handleClick(view, pos, event) {
        //   console.log(event.target)
        //   // do something
        // },
      }
    });

  },
  methods:{
    async openFileUpload(){
      this.$refs.fileInput.click()
    },
    async uploadFile(){
      if (!this.$refs.fileInput.files[0]) return;
      const form = new FormData();
      form.append('file', this.$refs.fileInput.files[0]);
      const temp = (await axios.post('/file/image', form, {headers: {"Content-Type": "multipart/form-data",}})).data;
      if (temp.id && this.testcaseId){
        axios.post(`/testcases/image/${this.testcaseId}`, {image_id: temp.id})
            .then(
                this.editor.chain().focus().setImage({ src: temp.filepath }).run()
            )

      }
    }
  }
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
  img{
    max-width: 80%;
    max-height: 100vh;
  }
  ul,
  ol {
    padding: 0 1.5rem;
    list-style-type: auto;
  }
  li {
    list-style-type: circle !important;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }
}

.bubble-menu {
  display: flex;
  background-color: #888888;
  padding: 0.2rem;
  border-radius: 0.5rem;
  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;
    font-size: 14px;
    color: white;
    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

</style>