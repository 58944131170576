<template>
  <h4>Space statistic</h4>


  <div class="card-panel">
    <ul>Created pads: {{statistic.pads.count}}
      <li>Items into pads: {{statistic.pads.items_count}}</li>
    </ul>

    <div class="runs_statistic">
      <div class="runs_statistic_list">
        <ul>Created runs: {{statistic.runs.count}}
          <li>All item: {{ statistic.runs.items_count }}</li>
          <li>Passed item: {{statistic.runs.passed}}</li>
          <li>Fail item: {{statistic.runs.fail}}</li>
          <li>Not status item: {{statistic.runs.not_status}}</li>
        </ul>
      </div>
      <div class="runs_statistic__chart">
        <statistic-pie-chart :data-chart="dataChartRuns" v-if="dataChartRuns"></statistic-pie-chart>
      </div>
    </div>

    <div class="bugs_statistic">
      <div class="bugs_statistic_list">
        <ul>Created bugs: {{statistic.bugs.count ?? 0}}
          <li>Open: {{statistic.bugs.open ?? 0}}</li>
          <li>Reopen: {{statistic.bugs.reopen ?? 0}}</li>
          <li>Fixed: {{statistic.bugs.fixed ?? 0}}</li>
          <li>Closed: {{statistic.bugs.closed ?? 0}}</li>
          <li>Hold: {{statistic.bugs.hold ?? 0}}</li>
        </ul>
      </div>
      <div class="bugs_statistic__chart">
        <statistic-pie-chart :data-chart="dataChartBugs" v-if="dataChartBugs"></statistic-pie-chart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StatisticPieChart from "@/components/statisctic/chart/StatisticPieChart";

export default {
  name: "SpaceStatisticComponent",
  components: {StatisticPieChart},
  data (){
    return {
      statistic: {
        pads: {
          count: undefined,
          items_count: undefined
        },
        runs: {
          count: undefined,
          items_count: undefined,
          passed: undefined,
          fail: undefined,
          not_status: undefined
        },
        bugs: {
          count: undefined,
          open: undefined,
          reopen: undefined,
          closed: undefined,
          fixed: undefined,
          hold: undefined
        }
      },
      spaceId: '',
      dataChartRuns: undefined,
      dataChartBugs: undefined,
    }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  async mounted(){
    if (this.statistic.bugs.count === undefined) {
      this.statistic = (await axios.get(`/space/${this.spaceId}/statistic`)).data
      const runsSt = this.statistic.runs;
      const bugsSt = this.statistic.bugs;
      this.dataChartRuns = {
        labelList: ['passed', 'fail', 'not status'],
        colorList: ['#41b883', '#ff0026', '#00eaff',],
        dataList: [runsSt.passed, runsSt.fail, runsSt.not_status]
      };
      this.dataChartBugs = {
        labelList: ['open', 'reopen', 'fixed', 'closed', 'hold'],
        colorList: ['#15d1d1', '#e6003a', '#e6bb00', '#07d401', '#26a69a'],
        dataList: [bugsSt.open, bugsSt.reopen, bugsSt.fixed, bugsSt.closed, bugsSt.hold]
      }
    }
  },
  async updated() {
    if (this.statistic.bugs.count === undefined) {
      this.statistic = (await axios.get(`/space/${this.spaceId}/statistic`)).data
      const runsSt = this.statistic.runs;
      const bugsSt = this.statistic.bugs;
      this.dataChartRuns = {
        labelList: ['passed', 'fail', 'not status'],
        colorList: ['#41b883', '#ff0026', '#00eaff',],
        dataList: [runsSt.passed, runsSt.fail, runsSt.not_status]
      };
      this.dataChartBugs = {
        labelList: ['open', 'reopen', 'fixed', 'closed', 'hold'],
        colorList: ['#15d1d1', '#e6003a', '#e6bb00', '#07d401', '#26a69a'],
        dataList: [bugsSt.open, bugsSt.reopen, bugsSt.fixed, bugsSt.closed, bugsSt.hold]
      }
    }
  }
}
</script>

<style scoped>
.runs_statistic, .bugs_statistic{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
</style>