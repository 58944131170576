<template>
  <div class="testcases_list_vtpad ">
    <div class="collection card" >
      <div class="collection-item" v-for="elem in testcases" :key="elem.id" @click="open(elem.id)">
        <span v-if="elem.id">{{elem.title}}</span>
      </div>
      <div class="collection-item select_testcases" @click="openTestcasesSelect(itemId)">
        <span>Select test case</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TestcasesVtpadComponent",
  emits: ["open-case", "open-case-select-list"],
  props:{
    itemId: String,
    updateCase: Number
  },
  watch: {
    'updateCase': {
      immediate: true,
      handler() {
        this.getTestcases();
      },
    },
  },
  data (){
    return{
      testcases: []
    }
  },
  async mounted(){
    await this.getTestcases();
  },

  methods: {
    async getTestcases(){
       axios.get(`/testcases/item/${this.itemId}?with_all=false&sort=asc&q=`)
      .then(value => {
        if (value.status === 200){
          this.testcases = value.data
        }
      })
    },
    close(){
      this.selectItem = undefined;
      this.$refs.modal_testcase.classList.toggle('open');
      this.$refs.modal__overlay.classList.toggle('open');
      this.modal = {
        header: '',
        content: '',
      }
    },

    open(elemId){
      this.$emit('open-case', elemId)
    },
    openTestcasesSelect(itemId){
      this.$emit('open-case-select-list', itemId)
    },
  }
}
</script>

<style scoped>

</style>