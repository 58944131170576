/* eslint-disable */
<template>
  <div class="vtpad_main">
    <run-select-component :pad-id="padId" />
    <div class='main_header '>
      <div>
        <label>
          <textarea
              type='text'
              class="materialize-textarea"
              v-model='newItem'
              @keydown.alt.enter="addItem($event, true)"
          />
        </label>
      </div>
    </div>
    <div class="vtpad card-panel" v-if="items.length > 0">
      <div v-for="item in items" :key="item.id" >
        <div class="vtpad-main_item">
          <div class="vtpad-main_item__checkbox">
            <label> <input type="checkbox" /> <span/> </label>

          </div>
          <div class="vtpad-main_item__textarea">
            <label>
              <textarea
                  type='text'
                  v-model="item.text"
                  :ref="'textarea_main'+item.id"
                  :id="item.id"
                  class="materialize-textarea"
                  @focusout="saveItem($event)"
                  @keydown.ctrl.enter="addSubItem($event)"
                  @keydown.alt.enter="addItem($event)"
                  @keydown.ctrl.delete="deleteItem($event)"

                  @keydown.down="nextMainFocused($event)"
                  @keydown.up="prevMainFocused($event)"


                  @keydown.alt.left="upMainItem($event)"
                  @keydown.alt.right="downMainItem($event)"
              />
            </label>

          </div>
          <div class="testcases_menu" @click="showTestcase(item.id)">
            <i class="material-icons">album</i>
          </div>
        </div>

        <testcases-vtpad-component
            v-if="item.showTestcases"
            :ref="`testcase_list_component__${item.id}`"
            :item-id="item.id"
            v-on:open-case="openTestcase"
            v-on:open-case-select-list="openTestcasesSelect"
            :update-case="item.updateCase"
        />

        <sub-item-component
            v-if="item.subItem"
            :item="item.subItem"
            :mainItemId="item.id"
            :pad-id="padId"
            v-on:open-case="openTestcase"
            v-on:open-case-select-list="openTestcasesSelect"
            :update-case="item.updateCase"
        />

      </div>
    </div>
  </div>

<div ref="modal_testcase" class="modal ">
  <div class="modal-header">
    <h4>{{modal.header}}</h4>
  </div>
  <div class="modal-content" v-html="modal.content">

  </div>
</div>

<div ref="modal_testcase__select_list" class="modal">
  <div class="modal-header">
    <h2>Select testcase</h2>
  </div>
  <div class="modal-content" >
    <label class="search">
      <input type="text"
             placeholder="search"
             ref="search_testcase__input"
             @input="searchTestcase($event)">
    </label>
    <div class="testcases_list">
      <div class="testcases_list-left card">
        <div class="collection">

          <div class="collection-item vertical-align"
               v-for="cases in testcasesList"
               :key="cases.id"
               :class="cases.active ? 'active' : ''">

            <label v-if="cases.into_item" @click="deleteCaseToItem(cases.id)"> <input type="checkbox" checked/> <span/> </label>
            <label v-else @click="addCaseToItem($event, cases.id)"> <input type="checkbox" /> <span/> </label>

            <span>{{cases.title}}</span>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal-overlay" ref="modal__overlay" @click="close"></div>
<div class="modal-overlay" ref="modal_testcase__select_list__overlay" @click="closeTestCaseList"></div>

</template>


<script>
/* eslint-disable */
import axios from 'axios'
import RunSelectComponent from "@/components/pads/run/RunSelectComponent";
import SubItemComponent from "@/components/pads/vtpad/subItemComponent";
import TestcasesVtpadComponent from "@/components/pads/vtpad/TestcasesVtpadComponent";

export default {
name: "VTPad",
  components: {TestcasesVtpadComponent, SubItemComponent, RunSelectComponent},
  data() {
    return {
      selectList: '',
      search_q: '',
      padId: this.$route.params.padId,
      newItem: '',
      items: [],
      modal: {
        header: '',
        content: '',
      },
      selectItem: undefined,
      tempCase: undefined,
      testcasesList: []
    };
  },
  async mounted() {
    this.selectList = '';
    this.items = [];
    this.padId =  this.$route.params.padId;
    await this.getItems();

  },
  methods: {
    async upMainItem(e){
      e.preventDefault();
      let index = this.items.findIndex((value) => value.id === e.target.id);
      if(!this.items[index-1]) return false;
      const prevIndex = index - 1;
      const temp = await axios.patch(`/items/${this.items[index].id}`, {
        sortBeforeId: this.items[prevIndex].id
      });
      if(temp.status === 200)
        this.items = temp.data

      await this.$refs[`textarea_main${this.items[index].id}`][0].focus()
      this.$refs[`textarea_main${this.items[index-1].id}`][0].focus()
    },
    async downMainItem(e){
      e.preventDefault();
      const index = this.items.findIndex((value) => value.id === e.target.id);
      if(!this.items[index+1]) return false;
      const prevIndex = index + 1;
      const temp = await axios.patch(`/items/${this.items[index].id}`, {
        sortAfterId: this.items[prevIndex].id
      });
      if(temp.status === 200)
        this.items = temp.data
      this.$refs[`textarea_main${this.items[prevIndex].id}`][0].focus()
    },

    async getItems(){
      this.items = (await axios.get(`/items/${this.padId}`)).data
    },
    async addItem(e, main=false){
      const indexElement = this.items.findIndex((value) => value.id === e.target.id);
      if(indexElement !== -1){
        await this.saveItem(e)
        const temp =
            (await axios.post(`/items/${this.padId}`, {text: ''})).data
        this.items.push(temp);
        return true;
      }
      if(!e.target.value.toString()) return undefined;
      const text = main ? e.target.value.toString() :''
      const temp =
          (await axios.post(`/items/${this.padId}`, {text: text})).data

      this.items.push(temp);
      return true;
    },
    async saveItem(e){
      const indexElement = this.items.findIndex((value) => value.id === e.target.id);
      if(indexElement === -1) return undefined;

      const temp = this.items[indexElement];
      if(!e.target.value.toString()) return undefined;
      this.items[indexElement].text = e.target.value.toString();
      await axios.put(`/items/${temp.id}`, {
        text: e.target.value
      });
    },
    async deleteItem(e){
      const id = e.target.id;
      const indexItem = this.items.findIndex((value) => value.id === id);
      if(indexItem === -1) return undefined;
      await this.prevMainFocused(e)
      this.items.splice(indexItem,1);
      await axios.delete(`/items/${id}`);

    },


    async addSubItem(e) {
      let id = '';
      if(e.target.getAttribute('ids')) id = e.target.getAttribute('ids');
      else id = e.target.id
      const indexElement = this.items.findIndex((value => value.id === id));
      if(indexElement === -1) return undefined;
      const temp = (await axios.post(`/items/${this.padId}`, {text: '', mainId: id})).data
      this.items[indexElement].subItem.push(temp);
    },
    async deleteSubItem(e){
      const id = e.target.id;
      const index = this.items.findIndex(value => value.id === e.target.getAttribute('ids'))
      const indexElement = this.items[index].subItem.findIndex((value) => value.id === id);
      if(indexElement === -1) return undefined;
      this.items[index].subItem.splice(indexElement,1);
      await axios.delete(`/items/${id}`);
      await this.prevSubFocused(e)
    },


    async nextMainFocused(e){
      e.preventDefault();
      const index = this.items.findIndex((value) => value.id === e.target.id);
      try {
        this.$refs[`textarea_main${this.items[index+1].id}`][0].focus()
      }catch (e) {
      }
    },
    async prevMainFocused(e){
      e.preventDefault();
      const index = this.items.findIndex((value) => value.id === e.target.id);
      if(!this.items[index-1]) return false;
      this.$refs[`textarea_main${this.items[index-1].id}`][0].focus()
    },

    async openTestcase(caseId){
      this.$refs.modal_testcase.classList.toggle('open');
      this.$refs.modal__overlay.classList.toggle('open');
      const temp = (await axios.get(`/testcases/detail/${caseId}`)).data;
      let contentModal = {
        text: '',
        steps: '',
        expectedResults: ''
      }

      try {
        contentModal.text = (temp.text.match(/[\wа-яА-Я a-zA-Z]{2}/g) ? `<h5>Description</h5><div class="card-panel">${temp.text}</div>`: '')
      }catch (e) {}
      try {
        contentModal.steps = (temp.steps.match(/[\wа-яА-Я a-zA-Z]{2}/g) ?`<h5>Steps</h5><div class="card-panel">${temp.steps}</div>`: '')
      }catch (e) {}
      try {
        contentModal.expectedResults = (temp.expected_results.match(/[\wа-яА-Я a-zA-Z]{2}/g) ?`<h5>Expected results</h5><div class="card-panel">${temp.expected_results}</div>`: '')
      }catch (e) {}
      let content = contentModal.text + contentModal.steps + contentModal.expectedResults
          // (temp.text.match(/[\w]{2}/g) ? `<h5>Description</h5><div class="card-panel">${temp.text}</div>`: '') +
          // (temp.steps.match(/[\w]{2}/g) ?`<h5>Steps</h5><div class="card-panel">${temp.steps}</div>`: '') +
          // (temp.expected_results.match(/[\w]{2}/g) ?`<h5>Expected results</h5><div class="card-panel">${temp.expected_results}</div>`: '')

      this.modal = {
        header: temp.title,
        content: content,
      }

    },
    closeTestcase(){
      this.modal = undefined;
      this.$refs.modal_testcase.classList.toggle('open');
      this.$refs.modal__overlay.classList.toggle('open');
    },
    async close(){
      this.modal = {
        header: undefined,
        content: undefined,
      }
      this.$refs.modal_testcase.classList.toggle('open');
      this.$refs.modal__overlay.classList.toggle('open');
    },
    async searchTestcase(e){
      this.search_q = e.target.value;
      if (!this.search_q) this.search_q = ''
      this.testcasesList = (await axios.get(`/testcases/item/${this.selectItem}?with_all=true&sort=asc&q=${this.search_q}`)).data;

    },
    async openTestcasesSelect(itemId, mainItemId=undefined){
      console.log(mainItemId, itemId)
      if (!this.search_q) this.search_q = ''
      this.selectItem = itemId;
      this.$refs.modal_testcase__select_list.classList.toggle('open');
      this.$refs.modal_testcase__select_list__overlay.classList.toggle('open');
      this.testcasesList = (await axios.get(`/testcases/item/${itemId}?with_all=true&sort=asc&q=`)).data;
      if(!mainItemId) {
        this.items.find(value => value.id === itemId).updateCase = 0
      }
      else{
        this.mainItemId = mainItemId;
        this.items.find(value => value.id === mainItemId).subItem.find(value => value.id === itemId).updateCase = 0
      }
    },
    async closeTestCaseList(){
      // await this.$refs[`testcase_list_component__${this.selectItem}`][0].getTestcases()

      this.selectItem = undefined;
      this.testcasesList = []
      this.$refs.modal_testcase__select_list.classList.toggle('open');
      this.$refs.modal_testcase__select_list__overlay.classList.toggle('open');
    },
    async deleteCaseToItem(caseId){
      if(!this.tempCase) {
        this.tempCase = caseId;
        this.testcasesList.find(value => value.id === caseId).into_item = false;
        const temp = await axios.delete(`/testcases-paditem/${this.selectItem}/${caseId}`)

      }else{
        this.tempCase = undefined;
      }
    },
    async addCaseToItem(e, caseId){

      if(!this.tempCase) {
        this.tempCase = caseId;
        await axios.put(`/testcases-paditem/${this.selectItem}/${caseId}`);
        this.testcasesList = (await axios.get(`/testcases/item/${this.selectItem}?with_all=true&sort=asc&q=`)).data;


      }else{
        this.tempCase = undefined;
      }
    },
    showTestcase(itemId){
      this.items.find(value => value.id === itemId).showTestcases = !this.items.find(value => value.id === itemId).showTestcases

    }
  },
};

</script>
<style lang="scss">
.modal-content {
  img{
    max-width: 100%;
  }
}
</style>
<style>
.sub{
  margin-left: 3vw;
}
.vtpad-main_item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.vtpad-main_item__checkbox {

}
.vtpad-main_item__textarea {
  width: 100%;
  margin-left: 15px;
}

.vtpad-sub_item {

}
.vtpad-sub_item.row{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}
.vtpad-sub_item__checkbox {

}
.vtpad-sub_item__textarea {
  width:  100%  ;
  margin-left: 15px;
}
.vtpad-sub_item .testcases_list_vtpad .collection{
  width:  calc(100% - 100px);
}
[type="checkbox"]+span:not(.lever):before, [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 3px;
  -webkit-transition: .2s;
  transition: .2s;
}
[type="checkbox"]:checked+span:not(.lever):before {
  top: -4px;
  left: -5px;
  width: 16px;
  height: 25px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.runs {
  margin: 0.5rem 0 1rem 0;
}
.testcases_menu{
  font-size: 30px;
  color: #26a69a;
  margin: 0 15px;
  cursor: pointer;
}
.testcases_list_vtpad{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.testcases_list_vtpad .collection{
  width: calc(100% - 100px);
  margin-left: 48px;
}
.testcases_list_vtpad .collection-item{
  cursor: pointer;
  color: #26a69a;
}
.testcases_list_vtpad .select_testcases{
  color: #e6003a;
}

.modal.open{
  z-index: 4001;
  display: block;
  opacity: 1;
  top: 10vh;
  transform: scaleX(1) scaleY(1);
  min-width: 600px;
  width: 80vw;
  max-width: 80vw;
  min-height: 60vh;
  max-height: 80vh;
}
.modal-overlay.open{
  z-index: 1002;
  display: block;
  opacity: 0.5;
}
.modal_bug{
  display: flex;
}
.modal-header{
  text-align: center;
}
.vertical-align{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
</style>