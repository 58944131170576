<template>

    <div class="switch" >
      <label>
        <input type="checkbox" @click="showHistory = !showHistory">
        <span class="lever"></span>
        show history
      </label>
    </div>

  <div class="card_comment" v-if="comments.length > 0 && comments[0].id">
    <div v-for="elem in comments" :key="elem.id">
      <div class="card-panel comment" v-if="elem.view === 'comment'" >
        <div class="card-content">
          <div class="card-content_header">
            <div class="card-content_header__username">
              {{elem.user.username ? elem.user.username : ''}}
            </div>
            <div class="card-content_header__createDate" @click="showDelete(elem.id)">
              <span class="card-content_header__createDate-date" :ref="`date_${elem.id}`">{{this.lux.fromISO(elem.create_date).toFormat('MMMM dd, yyyy HH:mm')}}</span>
              <span class="card-content_header__createDate-delete hidden" :ref="`delete_${elem.id}`" @click="deleteComment(elem.id)">Delete</span>
            </div>
          </div>
          <div class="card-content_text" >
            <BubbleMenuComponent :editor="elem.editor"  v-if="elem.editor" />
          </div>
        </div>
      </div>

      <div v-else-if="elem.view === 'history' && showHistory">
        <div class="comment_history" >
          <div class="comment_history-header">
            <p class="comment_history-header--username">
              {{elem.user.username ? elem.user.username : ''}}
            </p>
            <p class="comment_history-header--date">
              {{this.lux.fromISO(elem.create_date).toFormat('MMMM dd, yyyy HH:mm')}}
            </p>
          </div>

          <div class="comment_history-content" v-html="elem.text"></div>
        </div>
      </div>
    </div>

  </div>
  <div class="card-panel comment" >
    <BubbleMenuComponent :editor="newCommentEditor" @click="edit_comment($event)"/>
<!--    <div class="card-content_bottom">-->
<!--      <a class="btn-flat waves-light " @click="newComment">save</a>-->
<!--    </div>-->
  </div>
</template>

<script>
import axios from 'axios'
import { DateTime } from "luxon";


import BubbleMenuComponent from "@/components/editor/menu/BubbleMenuComponent";
import {Editor} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
export default {
  components: {
    BubbleMenuComponent
  },
  name: "PadBugCommentsComponent",
  props: {
    bug: {
      type: String,
      required: true,
    }
  },
  data(){
    return {
      showHistory: false,
      lux: DateTime,
      comments: [{
        id: '',
        create_date: '',
        text: '',
        bug_id: '',
        user_id: '',
        user: {
          username: '',
          avatar_id: ''
        }
      }],
      newCommentEditor: new Editor({
        extensions: [StarterKit,
          Image.configure({
            allowBase64: true,
            inline: true
          }),
          Link.configure({
            target: null,
            linkOnPaste: true,
            openOnClick: true,
          })],
        content: 'Add Comment',
      })
    }
  },
  async mounted() {
    this.comments = (await axios.get(`/comment/${this.bug}`)).data;
    for (const elem of this.comments){
      elem.editor =  new Editor({
        extensions: [StarterKit,
          Image.configure({
            allowBase64: true,
            inline: true
          }),
          Link.configure({
            target: null,
            linkOnPaste: true,
            openOnClick: true,
          })],
        content: elem.text,
      })
      elem.editor.on('blur', ()=>{this.saveComment(elem.id)})
    }
    this.newCommentEditor.on('focus', ()=>{this.newCommentEditor.commands.clearContent()})
    this.newCommentEditor.on('blur', ()=>{this.newComment()})
  },
  methods: {
    edit_comment(e){
      console.log(e)
    },
    showDelete(commentId){
      this.$refs[`date_${commentId}`][0].classList.add('hidden');
      this.$refs[`delete_${commentId}`][0].classList.remove('hidden');
    },
    async saveComment(commentId){
      let temp = this.comments.find((value => value.id === commentId));
      (await axios.put(`/comment/${temp.id}`, {
        text: temp.editor.getHTML()
      })).data;
    },
    async deleteComment(commentId){
      const temp = await axios.delete(`/comment/${commentId}`)
      if(temp.status === 200){
        const index = this.comments.findIndex(value => value.id === commentId)
        this.comments.splice(index, 1);
      }
    },
    async newComment(){
      if(!this.newCommentEditor.getText()) {
        this.newCommentEditor.commands.setContent('Add Comments');
        return;
      }
      const temp = (await axios.post(`/comment/${this.bug}`, {
        text: this.newCommentEditor.getHTML()
      }));
      if(temp.status === 200){
        const res = temp.data;
        res.editot = new Editor({
          extensions: [StarterKit,
            Link.configure({
              target: null,
              linkOnPaste: true,
              openOnClick: true,
            })],
          content: res.text
        });
        this.comments.push(res);
        this.comments.find((value => value.id === res.id)).editor = new Editor({
          extensions: [StarterKit,
            Link.configure({
              target: null,
              linkOnPaste: true,
              openOnClick: true,
            })],
          content: res.text
        })
        this.comments.find((value => value.id === res.id)).editor.on('blur', ()=>{this.saveComment(res.id)})
        this.newCommentEditor.commands.setContent('Add Comment');
      }
    },

  }
}
</script>

<style scoped lang="scss">
.switch{
  margin-left: 10px;
}
.comment_history{

  font-size: 12px;
  color: #737373;
  margin: 5px 30px;
  &-header{
    display: flex;
    p{
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
  &-content{
    ::v-deep p{
      margin: 0;
    }
  }
}

.card-panel.comment{
  margin: 10px 25px;

}
.ProseMirror {
  min-height: 100px;
  overflow: scroll;
}
.card-content_header{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.card-content_header div{
  font-size: .8rem;
  color: #9e9e9e;
}
.card-content_bottom{
  display: flex;
  flex-direction: row-reverse;
}
.card-content_header__createDate-delete.hidden, .card-content_header__createDate-date.hidden{
  display: none;
}
.card-content_header__createDate-delete{
  cursor: pointer;
  color: #e74c3c;
}
</style>