<template>
  <Line :data="data" :options="options" v-if="loaded" :style="myStyles"/>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
  name: "ReportTrendChart",
  components: {Line},
  props:{
    dataChart: {
      type: Object
    }
  },
  computed: {
    myStyles () {
      return {
        height: '150px',
        position: 'relative'
      }
    }
  },
  data() {
    return {
      loaded: false,
      data: undefined,
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins:{
          legend:{
            position: 'bottom'
          }
        }
      }
    }
  },
  mounted() {

    this.data = {
      labels: this.dataChart.label,
      datasets: [
        {
          label: 'passed',
          backgroundColor: '#41b883',
          data: this.dataChart.passed
        },
        {
          label: 'failed',
          backgroundColor: '#ff0026',
          data: this.dataChart.failed
        }
      ]
    };
    this.loaded = true;
  }
}
</script>

<style scoped>

</style>