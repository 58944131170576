<template>
  <form class="card-panel form-register" @submit.prevent="register">
    <label>
      <input type="text" placeholder="e-mail" v-model="mail">
    </label>
    <label>
      <input type="password" placeholder="password" v-model="password">
    </label>
    <div class="button_bottom">
      <a class="" href="/auth">Auth
<!--        <i class="material-icons right">send</i>-->
      </a>

      <button class="btn  waves-light" type="submit" name="action">Register
        <i class="material-icons right">add_box</i>
      </button>
    </div>



  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "RegisterComponent",
  data(){
    return {
      mail: '',
      password: ''
    }
  },
  methods:{
    async register(){
      if(!this.validEmail(this.mail)){
        alert('not mail');
        return;
      }
      if(this.password === '' || !this.password){
        alert('not password');
        return;
      }
      const temp = (await axios.post('/user', {
        mail: this.mail,
        password: this.password
      })).data
      try {
        if(temp.access_token){
          localStorage.setItem('token', temp.access_token);
          this.$store.state.token = temp.access_token;
          this.$router.push('/')
        }
      }catch (e){
        console.log(temp)
      }
    },
    validEmail(email) {
      const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>

<style scoped>
.button_bottom{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.form-register {
  max-width: max-content;
  margin: auto;
}
</style>