<template>
  <div class="card-panel">
    <div class="filter_testcase" >
      <label class="search">
        <input type="text"
               placeholder="search"
               ref="search_testcase__input"
               @input="searchTestcase($event)">
      </label>

      <label>
        <select  class="" ref="select_link__testcase" @change="reset($event, 'externalLink')">
          <option value=""  selected >Link</option>
          <option v-for="item in filter" :value="item.link" :key="item.link">{{item.task}}</option>
        </select>
      </label>
    </div>
    <div class="add_testcase__input">
      <label>
        <input type="text" @keypress.enter="createCase($event)" placeholder="type title and press enter"/>
      </label>
    </div>
  </div>
  <div class="testcases_list card" v-if="testcasesList.length > 0">
    <div class="testcases_list-left">
      <div class="collection">
        <div class="collection-item"
             v-for="cases in testcasesList"
             :key="cases.id"
             :class="cases.active ? 'active' : ''"
             @click="selectCase(cases.id)">
          <div class="testcases_name">
          <div class="testcase_name__short-name">{{cases.short_name}}</div>
          <div class="testcase_name__text"> {{cases.title}} </div>
          </div>
        </div>
      </div>
    </div>
    <div class="testcases_list-right">
      <testcases-right-component v-if="testcaseDetail" :cases="testcaseDetail" v-on:update-case="updateCase" v-on:delete-case="deleteCase"/>
    </div>
  </div>


</template>

<script>
import TestcasesRightComponent from "@/components/pads/testcases/TestcasesRightComponent";
import axios from "axios";
import M from "materialize-css";
export default {
  name: "TestcasesListComponent",
  components: {TestcasesRightComponent},
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  data() {
    return {
      testcasesList: [],
      search_q: undefined,
      filter: [
          {
            link: undefined,
            task: undefined
          }
      ],
      testcaseDetail: {
        title: undefined,
        text: undefined,
        steps: undefined,
        expected_results: undefined,
        id: undefined,
      }
    }
  },
  async mounted() {
    this.getFilter();
    await this.getCases();
    M.FormSelect.init(this.$refs.select_link__testcase, {isMultiple: false});
  },
  async updated(){

  },
  methods: {
    getFilter(){
      axios.get(`/testcases/filter/${this.spaceId}`).then(value => this.filter = value.data)
    },
    async searchTestcase(e){
      this.search_q = e.target.value;
      this.testcaseDetail = {
        title: undefined,
        text: undefined,
        steps: undefined,
        expected_results: undefined,
        id: undefined,
        shortName: undefined
      }
      this.getCases(this.search_q).then()
    },
    async getCases(){
      if (!this.search_q) this.search_q = ''
      const res = (await axios.get(`/testcases/${this.spaceId}?q=${this.search_q}`)).data;

      try {
        if (this.testcaseDetail.id) {
          res.find(value => value.id === this.testcaseDetail.id).active = true;
        }
        // eslint-disable-next-line
      }catch (e) {}
      this.testcasesList = res;

    },
    async selectCase(caseId){
      try {
        this.testcasesList.find(value => value.active === true).active = undefined;

        // eslint-disable-next-line
      }catch (e) { }
      finally {
        this.testcasesList.find(value => value.id === caseId).active = true;
      }
      const temp =  (await axios.get(`/testcases/detail/${caseId}`)).data; //this.testcasesList.find(value => value.id === caseId);
      temp.active = true;
      this.testcaseDetail = temp;
    },

    async createCase(e){
      if(!e.target.value) return;
      axios.post(`/testcases/`, {
        space_id: this.spaceId,
        title:e.target.value
      }).then(
          value => {
            if (value.status === 200){
              this.testcasesList.unshift(value.data);
              e.target.value = null;
            }
          }
      )
    },
    updateCase(){
      this.getCases();
    },
    deleteCase(caseId){
      const index = this.testcasesList.findIndex(value => value.id === caseId)
      this.testcasesList.splice(index, 1);
      this.testcaseDetail = {
          title: undefined,
            text: undefined,
            steps: undefined,
            expected_results: undefined,
            id: undefined,
      }
    },
    reset(e, filterName) {

      switch (filterName) {
        case 'externalLink':
          this.search_q = e.target.value ?? undefined;
          this.getCases()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.filter_testcase{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  label.search{
    width: 80%;
  }
}
.testcases_list{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 70vh;
}
.testcases_list-left{
  width: 30vw;
  margin-right: 15px;
}

.testcases_list-right{
  width: 38vw;
}
.collection-item{
  cursor: pointer;
  color: #26a69a;
}
.collection-item.active{
  background-color: rgba(0,0,0,0.05);
  color: #26a69a;
}
.testcases_list-left .collection{
  max-height: 98vh;
  overflow-y: auto;
  margin: 0;
}
.testcases_name{
  display: flex;
}
.testcase_name__short-name{
  margin-right: 10px;
  color: #e6003a;
  font-size: 10px;
}
</style>