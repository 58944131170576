<template>
  <form class="card-panel form-auth" @submit.prevent="login">
    <label>
      <input type="text" placeholder="e-mail" v-model="mail">
    </label>
    <label>
      <input type="password" placeholder="password" v-model="password">
    </label>
    <div class="button_bottom">
      <a class="" href="/register" >Register
<!--        <i class="material-icons right">add_box</i>-->
      </a>

      <button class="btn  waves-light" type="submit" name="action">Auth
        <i class="material-icons right">send</i>
      </button>

    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "AuthComponent",
  data(){
    return {
      mail: '',
      password: ''
    }
  },
  methods:{
    async login(){
      const temp = (await axios.post('/auth', {
        mail: this.mail,
        password: this.password
      })).data
      let token = ''
      try {
        token = temp.access_token
        if(token && token !== ''){
          localStorage.setItem('token', token);
          this.$store.state.token = token;
          window.location.href = '/'
        }
      }catch (e){
        console.log(temp)
      }

    }
  }
}
</script>

<style scoped>
.button_bottom{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.form-auth {
  max-width: max-content;
  margin: auto;
}
</style>