<template>
  <h5>Test Case</h5>
  <div class="">
    <div class="collection">
      <div class="collection-item collection-item_case">
        <div>Name</div>
        <div>Status</div>
        <div>Time</div>
        <div>Error Message</div>
      </div>

      <div class="collection-item collection-item_case" v-for="elem in cases" :key="elem.id">
        <div>{{elem.name}}</div>
        <div :class="elem.status.toLowerCase()">{{elem.status}}</div>
        <div>{{elem.duration}}</div>
        <div v-if="elem.message"> <a class="show-message_case" @click="showModalMessage(elem.id)">[...]</a> </div>
        <div v-else></div>
      </div>

    </div>
  </div>

  <div ref="modal_case_message" class="modal _case-message__class">
    <div class="modal-content" v-html="modalContent">

    </div>
  </div>

  <div class="modal-overlay" ref="modal_case_message__overlay" @click="close"></div>
</template>

<script>
export default {
  name: "ReportCaseComponent",
  props:{
    cases: {
      type: Array
    }
  },
  data(){
    return{
      modalContent: undefined
    }
  },
  methods:{
    showModalMessage(id){
      this.modalContent = this.cases.find(value => value.id === id).message;
      this.$refs.modal_case_message.classList.toggle('open');
      this.$refs.modal_case_message__overlay.classList.toggle('open');
    },
    close(){
      this.modalContent = undefined;
      this.$refs.modal_case_message.classList.toggle('open');
      this.$refs.modal_case_message__overlay.classList.toggle('open');
    },
  }
}
</script>

<style scoped>
.modal.open{
  z-index: 4001;
  display: block;
  opacity: 1;
  top: 10vh;
  transform: scaleX(1) scaleY(1);
  min-width: 600px;
  width: 80vw;
  min-height: 60vh;
  max-height: 80vh;
}
.modal-overlay.open{
  z-index: 1002;
  display: block;
  opacity: 0.5;
}
.collection-item_case{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}
.collection-item_case div{
  text-align: center;
  width: 250px;
}
.collection{
  border: initial;
  overflow: initial;
}
.passed{
  color: #98cc64;
}
.skipped{
  color: #ffd050;
}

.failed{
  color: #fc6766;
}
.unknown{
  color: #d35fbf;
}
a.show-message_case{
  cursor: pointer;
}
</style>