<template>


  <div class="collection">
    <div class="collection-item">

      <span class="badge new" @click="readAll">Read All</span>

      <span>Read All</span>
    </div>

    <div class="collection-item" v-for="elem in notification" :key="elem.id">

      <span class="badge new" v-if="elem.read === false" @click="readNotification(elem.id)">{{elem.event}}</span>
      <span class="badge " v-else>{{elem.event}}</span>

      <span v-html="elem.data"></span>
    </div>

  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "NotificationPageComponent",
  data(){
    return{
      notification: [{
        id:'',
        create_date: '',
        data: '',
        event: '',
        send: false,
        read: false,

      }]
    }
  },
  async mounted(){
    this.notification = (await axios.get('/notification')).data;
  },
  methods:{
    async readAll(){
      const temp = await axios.put(`/notification/read-all`);
      if (temp.status === 200) {
        this.notification = (await axios.get('/notification')).data;
        this.$store.commit('setNotificationCount', '0')
      }
    },
    async readNotification(id){
      const temp = await axios.put(`/notification/${id}/read`);
      if(temp.status === 200 && temp.data === 1){
        this.notification.find(value => value.id === id).read = true;
        this.$store.commit('decrementNotificationCount')
      }
    }
  }
}
</script>

<style scoped>
.badge{
  cursor: default;
}
.badge.new{
  cursor: pointer;
}
</style>