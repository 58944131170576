<template>
  <h4>Space settings</h4>
  <div>

    <div class="card-panel">
      <label>
        <input :value="space.name"
               :id="spaceId"
      @focusout="saveSpace($event)">
      </label>
    </div>



    <div class="">
      <h5>Users into space</h5>
    <ul class="collection">
      <li class="collection-item avatar" v-for="user in users" :key="user.userId">
        <img :src="getAvatar(user.filepath)" alt="" class="circle">
        <span class="title">{{ user.username ? user.username : user.mail }} - {{user.role.toLowerCase()}}</span>
        <p>{{ user.username ? user.mail : '' }}</p>

        <div class="secondary-content" v-if="user.role !== 'OWNER'">
          <a class="btn-link" @click="makeUserOwner(user.userId)">Make Owner</a>

          <a class="btn-link" @click="updateUserRule($event, user.userId, 'editPads')" :class="getRight(user.right, 'editPads') ? 'active':''">Edit Pads</a>
          <a class="btn-link" @click="updateUserRule($event, user.userId, 'editRuns')" :class="getRight(user.right, 'editRuns') ? 'active':''">Edit Runs</a>
          <a class="btn-link" @click="updateUserRule($event, user.userId, 'editItems')" :class="getRight(user.right, 'editItems') ? 'active':''">Edit Items</a>
          <a class="btn-link" @click="updateUserRule($event, user.userId, 'editNotes')" :class="getRight(user.right, 'editNotes') ? 'active':''">Edit Notes</a>
          <a class="btn-link" @click="updateUserRule($event, user.userId, 'closeBugs')" :class="getRight(user.right, 'closeBugs') ? 'active':''">Close Bugs</a>
          <a class="btn-link" @click="updateUserRule($event, user.userId, 'editTags')" :class="getRight(user.right, 'editTags') ? 'active':''">Edit Tags</a>

          <a class="btn-link"  ><i class="material-icons" @click="deleteUser(user.userId)">delete</i></a>
        </div>
      </li>
    </ul>
      <label >
        <input placeholder="enter user email and press enter" @keypress.down.enter="addUser($event)" @input="searchUser($event)">
      </label>
      <div class="collection" v-if="searchUserList">
        <div class="collection-item collection-item_search"
             v-for="elem in searchUserList"
             :key="elem.id"
             @click="addUserBuMail(elem.mail)"
        >{{elem.mail}}</div>
      </div>
    </div>

    <h5>Tag into space</h5>
    <div class="card-panel">

      <div class="tag_control">
        <label>
          <input type="text" placeholder="enter tag name" v-model="newTag.title">
        </label>

        <label>
          <input type="color" v-model="newTag.color">
        </label>


          <a class="btn  waves-light" @click="addTag($event)">
            save
            <i class="material-icons right">save</i>
          </a>

      </div>

      <div >
        <div class="tag_control" v-for="tag in tags" :key="tag.id">
          <label>
            <input type="text" v-model="tag.title">
          </label>

          <label>
            <input type="color" v-model="tag.color">
          </label>


          <div class="tag_control__btn">
              <a class="btn  waves-light" @click="updateTag($event, tag.id)">
                save
                <i class="material-icons right">save</i>
              </a>

            <a class="btn  waves-light" @click="deleteTag($event, tag.id)">
              delete
              <i class="material-icons right">delete</i>
            </a>
          </div>
        </div>
      </div>

    </div>

    <h5>Delete this space</h5>
    <div class="card-panel">
      <label >
        <input placeholder="enter for delete space 'DELETE'" v-model="deleteSpace">
      </label>
      <button class="btn waves-light" type="submit" name="action" @click="deleteSpaceFunc">delete
        <i class="material-icons right">delete</i>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SpaceSettingsComponent",
  data(){
    return{
      spaceId: '',
      space: {
        id: '',
        name: '',
        sort: 0,
      },
      spaceName: '',
      users: [{
        userId: "",
        spaceId: "",
        role: "",
        right: [],
        username: null,
        mail: ""
      }],
      deleteSpace: '',
      tags: [],
      newTag: {
        title: '',
        color: '#ff0000'
      },
      searchUserList: undefined,
    }
  },
  watch:{
    '$route.params': {
      immediate: true,
      handler() {
        this.spaceId = this.$route.params.spaceId;
      },
    },
  },
  async mounted() {
    this.space = (await axios.get(`/space/${this.spaceId}`)).data;
    this.spaceName = this.space.name;
    this.users = (await axios.get(`/space/${this.spaceId}/users`)).data;
    this.tags = (await axios.get(`/tag/${this.spaceId}`)).data
  },
  methods:{
    async updateUserRule(e, userId, rules){
      e.preventDefault();
      const userRight = this.users.find((value => value.userId === userId)).right;
      const currentValue = this.getRight(userRight, rules);
      let sendObj = {};
      switch (rules){
        case 'editPads':
          sendObj = {editPads: !currentValue}
          break;
        case 'editItems':
          sendObj = {editItems: !currentValue}
          break;
        case 'editRuns':
          sendObj = {editRuns: !currentValue}
          break
        case 'editNotes':
          sendObj = {editNotes: !currentValue}
          break
        case 'closeBugs':
          sendObj = {closeBugs: !currentValue}
          break
        case 'editTags':
          sendObj = {editTags: !currentValue}
      }
      let temp;
      try {
        temp = await axios.patch(`/space/${this.spaceId}/user/${userId}`,sendObj)
      }catch (err){
        return false;
      }
      this.users = temp.data;
    },
    async makeUserOwner(userId){
      const temp = await axios.put(`/space/${this.spaceId}/user-make-owner/${userId}`)
      if(temp.status !== 403 || temp.status !== 500)
        this.users = temp.data
    },
    async deleteSpaceFunc(){
      if(this.deleteSpace === 'DELETE'){
        await axios.delete(`/space/${this.spaceId}`);
        location.href = '/'
      }
    },
    getAvatar(filepath){
      if(filepath)
        return filepath
      else
        return  '/uploads/default.jpg'
    },
    getRight(jsons, key){
      try {
        const temp = JSON.parse(jsons)
        if (key in temp){
          return temp[key]
        }
        return false
      }catch (e) {
        return false;
      }
      
    },
    async saveSpace(e){
      try {
        await axios.put(`/space/${this.spaceId}`, {
          name: e.target.value
        });
      }catch (err){
        e.target.value = this.spaceName
      }
    },
    async addUser(e){
      const temp = (await axios.put(`/space/${this.spaceId}/user`, {
        mail: e.target.value.trim().toLowerCase()
      })).data
      if(temp!=null) {
        this.users = temp
        e.target.value = ''
      }
    },
    async addUserBuMail(mail){
      axios.put(`/space/${this.spaceId}/user`, {
        mail: mail
      }).then(value => {
        if(value.data != null){
          this.users = value.data
        }
      })
    },
    async deleteUser(userId){
      const temp = (await axios.delete(`/space/${this.spaceId}/user/${userId}`)).data
      if(temp!=null && Array.isArray(temp))
        this.users = temp
    },
    async updateTag(e, tagId){
      let tag = this.tags.find((value => value.id === tagId));
      const temp = (await axios.put(`/tag/${tagId}`, {title: tag.title, color: tag.color})).data
      if(temp.id) tag = temp

    },
    async addTag(){
      const temp = (await axios.post(`/tag/${this.spaceId}`, this.newTag)).data
      if (temp.id){
        this.tags.push(temp)
      }
    },
    async deleteTag(e, tagId){
      const temp = await axios.delete(`/tag/${tagId}`)
      if (temp.status === 200){
        this.tags = (await axios.get(`/tag/${this.spaceId}`)).data
      }
    },
    async searchUser(e){
      if(!e.target.value){
        this.searchUserList = undefined
        return
      }
      axios.get(`/user/search/by-mail?mail=${e.target.value.trim().toLowerCase()}`).then(value => {
        this.searchUserList = value.data
      })
    }
  }
}
</script>

<style scoped>
.collection-item_search{
  cursor: pointer;
  color: #26a69a;
}
.btn-link{
  cursor: pointer;
}
.secondary-content a{
  margin-left: 15px;
}
.secondary-content{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.secondary-content a.active{
  color: #ee6e73;
}
.tag_control{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.tag_control label{
  margin: 0 15px;
}
.tag_control__btn a {
  margin-right: 15px;
}
</style>